import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { AppComponent } from 'src/app/app.component';

import { Chat } from 'src/app/models/chat';

import { ChatService } from 'src/app/services/chat.service';
import { VideoService } from 'src/app/services/video.service';
import { StreamReport } from 'src/app/models/stream-report';
import { EmojiCustom } from '../../models/emoji-custom';
import { environment } from '../../../environments/environment';
import { RemoteConfigService } from '../../services/remote-config.service';
import { SocketService } from '../../services/socket.service';
import { BehaviorSubject, interval, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LiveService } from '../../services/live.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

    @Input() livestreamId: number;
    @Input() isPopOut = false;

    @ViewChild('scrollMe') private chatContainer: ElementRef;

    container: HTMLElement;
    video: HTMLElement;
    viewers$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    hideEmoji: boolean = true;

    customEmojis: EmojiCustom[];

    includes = [
        'search',
        'recent',
        'custom',
        'people',
        'symbols'
    ];

    translations = {
        search: 'Search',
        emojilist: 'List of emoji',
        notfound: 'No Emoji Found',
        clear: 'Clear',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            custom: environment.name,
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
        },
        skintones: {
            1: 'Default Skin Tone',
            2: 'Light Skin Tone',
            3: 'Medium-Light Skin Tone',
            4: 'Medium Skin Tone',
            5: 'Medium-Dark Skin Tone',
            6: 'Dark Skin Tone',
        }
    };


    current: any;

    autoscroll: boolean = true;
    allowedToSend: boolean = true;
    chat: Chat = new Chat();
    comments: any[] = [];

    mediaId: string;

    initialised = false;
    isLive = false;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private chatService: ChatService,
        public config: RemoteConfigService,
        private socket: SocketService,
        private liveService: LiveService) {

        this.mediaId = 'live';
    }

    ngOnInit() {

        if (this.livestreamId === undefined) {
            const id = +this.route.snapshot.paramMap.get('id');
            if (id > 0) {
                this.livestreamId = id;
            }
        }

        this.container = document.getElementById('chat-container');
        this.video = document.getElementById('video');
        this.chatService.getEmojis().subscribe(emojis => {
            this.customEmojis = emojis;
        });

        this.liveService.status$.pipe(takeUntil(this.destroy$)).subscribe(status => {
            if (!status) {
                return;
            }
            this.isLive = status.isLive;

            if (this.livestreamId && status.streams && status.streams.length > 0) {
                const stream = status.streams.find(x => x.livestreamId === this.livestreamId);
                this.viewers$.next(stream?.watching ?? 0);
                return;
            }

            if (!this.livestreamId && status.streams && status.streams.length > 0) {
                this.livestreamId = status.streams[0].livestreamId;
                this.initialiseChat()
            }
        });
        this.initialiseChat()
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initialiseChat() {
        if (this.initialised) {
            return;
        }

        if (!this.livestreamId) {
            return
        }

        this.initialised = true;
        this.liveService.getChat(this.livestreamId).pipe(takeUntil(this.destroy$)).subscribe(chat => {
            this.comments.unshift(...chat);
        })

        this.socket.onMessage('livestream-' + this.livestreamId).pipe(takeUntil(this.destroy$)).subscribe(data => {
            const parsed = JSON.parse(data);
            if (parsed.type === 'ping') {
                console.log('LIVE PING: ', parsed);
                this.viewers$.next(parsed.body.watching);
                return;
            } else {
                if (parsed.error) {
                    this.comments.push({ message: parsed.error });
                } else {
                    this.comments.push(parsed.body);
                    this.scrollToBottom();
                }
            }
        });

        this.liveService.sendPing(this.livestreamId);
        interval(30000).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (!this.isLive) {
                return;
            }
            this.liveService.sendPing(this.livestreamId);
        });
    }

    send() {
        this.chat.message = this.chat.message.trim();
        if (!this.allowedToSend || this.chat.message.trim().length === 0) {
            return;
        }
        setTimeout(() => {
            this.allowedToSend = true;
        }, 500);

        this.liveService.sendChatMessage(this.livestreamId, this.chat.message);

        this.allowedToSend = false;
        this.chat = new Chat();
    }

    scrollToBottom(): void {
        if (this.autoscroll) {
            try {
                if (this.current !== this.chatContainer.nativeElement.scrollHeight) {
                    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
                    this.current = this.chatContainer.nativeElement.scrollHeight;
                }
            } catch (err) {
            }
        }
    }


    emoji() {
        this.hideEmoji = this.hideEmoji !== true;
    }

    addEmoji(event) {
        if (event.emoji.native) {
            if (this.chat.message) {
                this.chat.message += event.emoji.native;
            } else {
                this.chat.message = event.emoji.native;
            }
        } else {
            if (this.chat.message) {
                this.chat.message += event.emoji.colons;
            } else {
                this.chat.message = event.emoji.colons;
            }
        }
    }
}
