import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MetadataService } from '../../services/metadata.service';
import { Utils } from '../../services/utils.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';
import { WatchHistoryService } from '../../services/watch-history.service';
import { PlaylistService } from '../../services/playlist.service';
import { MediaSummary } from '../../models';
import { CreatorTeamService } from '../../services/creator-team.service';

@Component({
    selector: 'app-video-thumb-open',
    templateUrl: './video-thumb-open.component.html',
    styleUrls: ['./video-thumb-open.component.scss']
})
export class VideoThumbOpenComponent implements OnInit, OnDestroy {

    @Input() media: MediaSummary;
    @Input() lazyLoadOff: boolean;
    @Input() recommendationId: string = undefined;

    loadImages = false;
    progress: number;
    timeAgo: string;
    newVideo: boolean = false;
    watchSubscription: Subscription;
    watched: Boolean = false;

    showMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    addToPlaylistLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    follows$ = this.creatorTeamService.getMyFollows();

    constructor(private metadataService: MetadataService,
                public config: RemoteConfigService,
                private watchHistoryService: WatchHistoryService,
                private playlistService: PlaylistService,
                private creatorTeamService: CreatorTeamService) {
    }

    ngOnInit(): void {
        if (this.lazyLoadOff) {
            this.load();
        }

        if (this.media.previewImage?.indexOf('?a=') > 0) {
            this.media.previewImage = this.media.previewImage + '&w=600'
        }

        if (!this.media.title && this.media.mediaId) {
            this.metadataService.getMetadata(this.media.mediaId).subscribe(res => {
                this.media.title = res.metadata.title;
                this.media.shortDescription = res.metadata.shortDescription;
                this.timeAgo = Utils.getTime(this.media.datePublished);
            });
        } else {
            if (this.media.images) {
                this.media.image = this.media.images.find(x => x.type === 'thumbnail')?.url;
                this.media.channelBadgeUrl = this.media.images.find(x => x.type === 'channel-badge')?.url;
                this.media.previewImage = this.media.images.find(x => x.type === 'media-preview')?.url;
            }
            this.timeAgo = Utils.getTime(this.media.datePublished);
        }

        this.watchSubscription = this.watchHistoryService.watchHistory.subscribe(res => {
            this.watchHistory(res);
        });
    }

    ngOnDestroy(): void {
        this.watchSubscription.unsubscribe();
    }

    checkNew() {
        this.newVideo = false;
        const timestamp = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        const releaseTime = new Date(this.media.datePublished).getTime();

        if (releaseTime > timestamp && (!this.progress || this.progress <= 0)) {
            this.newVideo = true;
        }
    }

    watchHistory(history: any[]) {
        if (history && history.length > 0) {
            let result = history.find(f => f.mediaId == this.media.mediaId);
            if (result) {
                this.progress = result.percentage;
            }

            if (!this.progress || this.progress <= 0) {
                this.checkNew();
            } else {
                this.watched = this.config.remoteConfig.watched_tick_enabled && true;
            }
        }
    }

    addToPlaylist(media: MediaSummary) {
        if (!this.addToPlaylistLoading$.value) {
            this.addToPlaylistLoading$.next(true);
            this.playlistService.addToPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading$.next(false);
                },
                error: () => {
                    this.addToPlaylistLoading$.next(false)
                }
            });
        }
    }

    deleteFromPlaylist(media: MediaSummary) {
        if (!this.addToPlaylistLoading$.value) {
            this.addToPlaylistLoading$.next(true);
            this.playlistService.deleteFromPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading$.next(false);
                },
                error: () => {
                    this.addToPlaylistLoading$.next(false);
                }
            });
        }
    }

    existsInPlaylist() {
        return this.playlistService.hasMediaId(this.media.mediaId);
    }

    load() {
        this.loadImages = true;
    }

    togglePlaylist() {
        if (this.existsInPlaylist()) {
            this.deleteFromPlaylist(this.media);
            return;
        }

        this.addToPlaylist(this.media);
    }

    toggleFollow(isFollowing: boolean, teamId: number) {
        if (isFollowing) {
            this.creatorTeamService.unfollowTeam(teamId).subscribe();
        } else {
            this.creatorTeamService.followTeam(teamId).subscribe();
        }
    }
}
