import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { ChannelResponse } from '../../models';
import { Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { UiService } from '../../services/ui.service';
import { RecommendationsService } from '../../services/recommendations.service';

@Component({
    selector: 'app-user-recommendations',
    templateUrl: './user-recommendations.component.html',
    styleUrls: ['./user-recommendations.component.css']
})
export class UserRecommendationsComponent implements OnInit, OnDestroy {

    @Input() channel: ChannelResponse;

    mediaList: any[];

    loaded: boolean = false;

    destroy = new Subject<void>()
    isMobileView = false;

    constructor(
        private ui: UiService,
        private recommendationService: RecommendationsService,) {
        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    ngOnInit(): void {
        this.recommendationService.getRecommendations('recommended-for-you').pipe(takeUntil(this.destroy))
            .subscribe((recommendations) => {
                this.mediaList = recommendations.media;
            });
    }

    ngOnDestroy() {
        this.destroy.next();
    }

}
