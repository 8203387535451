import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AccountService} from './account.service';
import {EnvironmentService} from './environment.service';
import {ChannelResponse, Channel} from '../models';

@Injectable({
    providedIn: 'root'
})
export class ChannelsService {

    private channelVersion = 'v12';

    private historySubject = new BehaviorSubject<any[]>(null);

    userChannelSubject = new BehaviorSubject<any[]>(null);

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
        private accountService: AccountService) {
        this.accountService.loggingOut.subscribe(() => this.clearHistorySubject());
    }

    clearHistorySubject() {
        this.historySubject.next(null);
    }

    getChannels(hero: boolean, showFree: boolean): Observable<ChannelResponse[]> {
        return this.http
            .get<ChannelResponse[]>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '?hero=' + hero + '&showFree=' + showFree);
    }

    getLatestVideosChannel(): Observable<ChannelResponse> {
        return this.http
            .get<ChannelResponse>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/latest');
    }

    getChannelById(channelId: number): Observable<ChannelResponse> {
        return this.http
            .get<ChannelResponse>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/' + channelId);
    }

    getChannelBySeasonId(seasonId: number): Observable<ChannelResponse> {
        return this.http
            .get<ChannelResponse>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/season/' + seasonId);
    }

    getChannelsShows(): Observable<ChannelResponse[]> {
        return this.http
            .get<ChannelResponse[]>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/shows');
    }

    getChannelsShow(channelId: number): Observable<ChannelResponse> {
        return this.http
            .get<ChannelResponse>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/' + channelId);
    }

    getChannelBySlug(slug: string): Observable<ChannelResponse> {
        return this.http
            .get<ChannelResponse>(this.envService.contentCdnHost + '/channels/' + this.channelVersion + '/slug/' + slug);
    }

    getUserChannels(): Observable<any> {
        return this.http
            .get<any>(this.envService.contentHost + '/v4/userchannels/');
    }

    userChannels(): Observable<any[]> {
        return this.userChannelSubject.asObservable();
    }

    async refreshUserChannels() {
        if (!this.accountService.isAuthenticated()) {
            return;
        }
        await this.accountService.updateToken().then(
            () => {
                this.getUserChannels().subscribe(res => {
                    this.userChannelSubject.next(res);
                });
            },
            () => {
            });
    }
}
