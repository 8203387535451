import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { VideoService } from '../../services/video.service';
import { Title } from '@angular/platform-browser';
import { RemoteConfigService } from '../../services/remote-config.service';
import { UserInfoService } from '../../services/user-info.service';
import { environment } from '../../../environments/environment';
import { VideoResponse } from '../../models';
import { catchError } from 'rxjs/operators';
import { StorageManagerService } from '../../services/storage-manager.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserPreferenceDefaults, UserPreferenceKey } from '../../models/enums/user-preferences.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {

    @Input() mediaId: number;
    @Input() autoPlay = true;
    @Input() recommendationId: string = undefined;
    @Output() safariHls = new EventEmitter<boolean>();
    @Output() videoLoaded = new EventEmitter<boolean>();
    videoHasLoaded$ = new BehaviorSubject(false);
    authed: boolean = false;

    safari = false;

    video$: Observable<VideoResponse>;
    maxBitrate$ = this.preferences.getOne(UserPreferenceKey.VIDEOQUALITY_Other).pipe(
        map(x => +x.value),
        catchError(x => of(UserPreferenceDefaults.VIDEOQUALITY_Other))
    );

    combined$: Observable<{ video: VideoResponse, bitrate: number }>;

    destroy$: Subject<void> = new Subject<void>();
    showReportReasons: boolean = false;
    theatre: boolean = false;

    deviceLimitError = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public accountService: AccountService,
        private videoService: VideoService,
        private titleService: Title,
        public config: RemoteConfigService,
        private userInfo: UserInfoService,
        private storage: StorageManagerService,
        private preferences: UserPreferencesService
    ) {
        this.authed = accountService.isAuthenticated();
        this.safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.theatre = this.storage.getPlayerSize() === 'theatre';
    }

    ngOnInit(): void {
        let platform = 'Web';
        if (this.safari === true) {
            platform = 'Safari';
        }

        if (this.userInfo.hasSubscription !== undefined || !this.authed) {
            this.videoLogic(platform);
        } else {
            let attempts = 0;
            const interval = setInterval(() => {
                if (this.userInfo.hasSubscription !== undefined || attempts >= 3) {
                    clearInterval(interval);
                    if (this.userInfo.hasSubscription !== undefined) {
                        this.videoLogic(platform);
                    }
                }
                attempts++;
            }, 500);
        }
    }

    ngOnDestroy() {
        this.titleService.setTitle(environment.name);
        this.destroy$.next();
        this.destroy$.complete();
    }

    videoLogic(platform: string) {
        if (this.userInfo.hasSubscription && this.accountService.isAuthenticated()) {
            this.accountService.updateToken().then(() => {
                this.video$ = this.videoService.getVideo(this.mediaId, platform, this.recommendationId)
                    .pipe(
                        map(x => this.mapStartTime(x)),
                        catchError(err => {
                            if (err.status === 404) {
                                this.router.navigate(['/not-found']);
                            } else if (err.status === 400) {
                                this.handle400(err);
                                throw err;
                            } else {
                                this.router.navigate(['/']);
                            }
                            this.accountService.checkUnauthorized(err);
                            throw err;
                        })
                    );
                this.combined$ = combineLatest([this.video$, this.maxBitrate$])
                    .pipe(map(([video, bitrate]) => ({ video, bitrate })));
            });
        } else {
            if (this.accountService.isAuthenticated()) {
                this.video$ = this.videoService.getFreeVideo(this.mediaId, platform)
                    .pipe(
                        map(x => this.mapStartTime(x)),
                        catchError(err => {
                            if (err.status === 400) {
                                this.handle400(err);
                                throw err;
                            }
                            this.accountService.nextRoute = this.router.url;
                            this.router.navigate(['/payment']);
                            throw err;
                        })
                    );
                this.combined$ = combineLatest([this.video$, this.maxBitrate$])
                    .pipe(map(([video, bitrate]) => ({ video, bitrate })));
            } else {
                this.video$ = this.videoService.getFreeVideo(this.mediaId, platform)
                    .pipe(
                        map(x => this.mapStartTime(x)),
                        catchError(err => {
                            if (err.status === 400) {
                                this.handle400(err);
                                throw err;
                            }

                            this.accountService.nextRoute = this.router.url;
                            this.router.navigate(['/signup']);
                            throw err;
                        })
                    );
                this.combined$ = combineLatest([this.video$, this.maxBitrate$])
                    .pipe(map(([video, bitrate]) => ({ video, bitrate })));
            }
        }
    }

    handle400(error: HttpErrorResponse) {
        if (error.error['device-identifier']) {
            this.deviceLimitError = true;
        }
        if (error.error === 'Content is not available under a free account.') {
            this.accountService.nextRoute = this.router.url;
            this.router.navigate(['/payment']);
        }
    }

    mapStartTime(videoResult: VideoResponse): VideoResponse {
        this.safariHls.emit((this.safari === true && videoResult.hlsUrl != undefined) || (videoResult.hlsUrl != undefined && !videoResult.dashUrl));

        this.videoLoaded.emit(videoResult.dashUrl != undefined || videoResult.hlsUrl != undefined || videoResult.fpsHlsUrl != undefined);
        this.videoHasLoaded$.next(true);

        const time = this.route.snapshot.queryParamMap.get('t');
        if ((time === null || time === undefined || time === '')) {
            return videoResult;
        }

        // contains a time type or is invalid
        if (isNaN(+time)) {
            const regex = new RegExp(/([0-9]+h)?([0-9]+m)?([0-9]+s)?/i);
            let match = regex.exec(time);
            let timeMS = 0;

            // no matches so string is invalid
            if (match === null || match === undefined) {
                return videoResult;
            }

            // Hours
            if (!(match[1] === null || match[1] === undefined)) {
                const t = +match[1].replace('h', '');
                timeMS += t * 1000 * 60 * 60;
            }

            // Minutes
            if (!(match[2] === null || match[2] === undefined)) {
                const t = +match[2].replace('m', '');
                timeMS += t * 1000 * 60;
            }

            // Seconds
            if (!(match[3] === null || match[3] === undefined)) {
                const t = +match[3].replace('s', '');
                timeMS += t * 1000;
            }

            videoResult.startTimeMs = timeMS;
        } else { // When a plain number, assume seconds
            videoResult.startTimeMs = +time * 1000;
        }
        return videoResult;
    }
}
