import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ScreenSizeEnum} from '../../models/screen-size.enum';
import {Subject, takeUntil} from 'rxjs';
import {UiService} from '../../services/ui.service';
import {SocketService} from '../../services/socket.service';
import {LiveService} from "../../services/live.service";

@Component({
    selector: 'app-live-menu',
    templateUrl: './live-menu.component.html',
    styleUrls: ['./live-menu.component.scss']
})
export class LiveMenuComponent implements OnInit, OnDestroy {

    @Input() big = false;

    isLive: boolean = false;

    countDownDate: number = null;
    timeUntilLs: string;
    isMobileView: boolean = false;

    livestreamId: number = 0;

    intervalId;
    distance = 0;
    statusReceived = false;

    private destroy$ = new Subject<void>();

    constructor(
        private liveService: LiveService,
        private ui: UiService) {

        this.liveService.status$.subscribe(status => {
            if (status == null) {
                return;
            }

            this.statusReceived = status.nextStream !== null;

            this.isLive = status.isLive;
            if (status.nextStream) {
                this.countDownDate = new Date(status.nextStream).getTime();
            }
            if (status.nextStream && status.streams.length > 0) {
                this.livestreamId = status.streams[0].livestreamId;
            } else {
                this.livestreamId = undefined;
            }
            this.checkLiveCountdown();
        });

        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize === ScreenSizeEnum.XSmall;
            });
    }

    ngOnInit() {
    }

    checkLiveCountdown() {
        this.doCountdown();
        this.intervalId = setInterval(() => {
            this.doCountdown()
        }, 2000);
    }

    doCountdown() {
        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count-down date
        this.distance = this.countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

        if (isNaN(days)) {
            return;
        }

        // Display the result in the element with id="demo"
        this.timeUntilLs = '';

        if (days > 0) {
            this.timeUntilLs = days + 'd ';
        }
        this.timeUntilLs += hours + 'h '
            + minutes + 'm';

        if (this.distance < 0) {
            this.timeUntilLs = 'SOON';
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        clearInterval(this.intervalId);
    }
}
