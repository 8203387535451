import { Component, OnInit } from '@angular/core';
import { AuthenticationOverride } from '../../modules/authentication-override';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../services/storage-manager.service';
import { BingoService } from '../../services/bingo.service';
import { BingoCardChoiceResponse, BingoCardLayout, BingoGameResponse } from '../../models/bingo.models';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { UserInfoService } from '../../services/user-info.service';
import { AccountType } from '../../models/enums/account-type.enum';

@Component({
    selector: 'app-bingo',
    templateUrl: './bingo.component.html',
    styleUrls: ['./bingo.component.scss']
})
export class BingoComponent extends AuthenticationOverride implements OnInit {
    protected readonly AccountType = AccountType;

    layout$: Observable<BingoCardLayout>;
    creatorLayouts$ = this.service.getLayouts().pipe(tap(x => {
        this.selectedCreatorLayout = x?.[0].id;
        console.log(this.selectedCreatorLayout);
    }));

    myGames: number[] = [];
    bingoId: number = undefined;
    gameId: number = undefined;
    cardId: number = undefined;
    bingo = false;
    noGame$ = new BehaviorSubject(false);

    choices: BingoCardChoiceResponse[] = [];
    selectedCreatorLayout: number;

    constructor(account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router,
                private service: BingoService,
                public userInfo: UserInfoService) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();

        // TODO: Get Bingo/Game ID from socket

        this.service.newGame$.subscribe(newGameId => {
            if (!newGameId) {
                return;
            }

            this.myGames.unshift(newGameId);

            if (!this.bingoId) {
                this.initialiseGame();
                return;
            }
        });

        this.initialiseGame();
    }

    initialiseGame() {
        this.service.getActiveGame()
            .subscribe(
                activeGame => {
                    this.bingoId = activeGame.bingoId;
                    this.gameId = activeGame.gameId;
                    this.noGame$.next(false);

                    this.layout$ = this.service.getLayout(this.bingoId);

                    this.service.getMyGames(this.bingoId).subscribe(games => {
                        this.myGames = [...new Set<number>([...this.myGames, ...games.map(x => x.gameId)])];
                    });

                    this.getCard();
                },
                error => {
                    this.noGame$.next(true);
                }
            );
    }

    getCard() {
        this.service.getCard(this.bingoId, this.gameId).subscribe(card => {
            this.choices = card.choices;
            this.cardId = card.id;
            this.bingo = !!card.bingo;
        });
    }

    toggleCheck(choice: BingoCardChoiceResponse) {
        if (choice.disabled) {
            return;
        }
        choice.flipped = !choice.flipped;
        choice.disabled = true;

        this.service.flipChoice(this.bingoId, this.gameId, choice.id).subscribe((x) => {
            choice.disabled = false;
            this.bingo = x.bingo;
        });
    }

    startNewGame() {
        this.service.startGame(this.selectedCreatorLayout).subscribe();
    }

    openGame(gameId: number) {
        if (this.gameId === gameId) {
            return;
        }

        this.gameId = gameId;
        this.getCard();
    }
}
