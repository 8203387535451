<div #imageSlider class="is-container" [class.is-container-slim]="slim" (window:resize)="onResize($event)" [class.p-0]="disableHover"
    [class.mb-1]="disableHover" appSwipe (swipeLeft)="navRight($event)" (swipeRight)="navLeft($event)">
    <div class="is-inner-container" [class.flex-wrap]="rows > 1" [style.transform]="transform">
        <div *ngFor='let item of displayItems; let i = index' [class.is-first]="i === first"
            [class.is-last]="i === last" [style.width.%]="width" [style.flex]="flex"
            [class.is-item-no-hover]="disableHover" [class.is-item]="!disableHover" [class.is-item-z]="!disableHover"
            [class.is-item-large]="numberOfItems < 4" class="px-lg-2" [class.mb-3]="rows > 1 && i < (numberOfItems / 2)">
            <ng-container *ngIf="!disableNextRow || (disableNextRow && i < numberOfItems)">
                <app-video-thumb-v2 [fromWatchList]="watchList" *ngIf="type == 'thumbs' || type.startsWith('media-list') || type === 'season-banner' || type === 'hot-list' || type === 'user-recommendations'" [media]="item"
                    [hoverOnSmall]="true" [lazyLoadOff]="type === 'media-list-channel'" [thumbnailType]="thumbnailType">
                </app-video-thumb-v2>

                <app-schedule-thumb *ngIf="type == 'schedule'" [item]="item"></app-schedule-thumb>

                <app-show-thumb *ngIf="type == 'show-list'" [item]="item"></app-show-thumb>

                <div *ngIf="type === 'greenlight-past-winners'" class="w-100">
                    <img [src]="item.url" class="w-100">
                </div>

            </ng-container>
        </div>
    </div>
    <button *ngIf="left && !disableNextRow" type="button" role="presentation" class="is-nav is-prev" (click)="navLeft($event)"
        [class.h-100]="disableHover" aria-label="Scroll Left">
        <div class="is-nav-bg"></div><img height="42" class="slider-arrow-left"
            src="{{config.remoteConfig.slider_left}}" alt="Scroll Left">
    </button>
    <button *ngIf="right && !disableNextRow" type="button" role="presentation" class="is-nav is-next"
        (click)="navRight($event)" [class.h-100]="disableHover" aria-label="Scroll Right">
        <div class="is-nav-bg"></div><img height="42" class="slider-arrow-right"
            src="{{config.remoteConfig.slider_right}}" alt="Scroll Right">
    </button>
</div>
