<div class="main main-full">
    <div class="d-flex flex-column flex-lg-row">
        <ng-container *ngIf="layout$ | async as layout;">
            <div class="bingo-card">
                <div class="bingo-card-banner" [style.background-image]="'url(' + layout.bannerImage +')'">
                </div>
                <div class="bingo-grid" [style.background-image]="'url(' + layout.backgroundImage +')'">
                    <div class="bingo-phrase" *ngFor="let phrase of choices" (click)="toggleCheck(phrase)">
                        {{phrase.word}}
                        <img class="checkmark" [src]="layout.checkmarkImage" *ngIf="phrase.flipped" draggable="false">
                    </div>
                </div>
                <div class="card-id">
                    Card ID: {{cardId}}
                </div>

                <div class="bingo-win" *ngIf="bingo" (click)="bingo = false">
                    <div class="close-bingo">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                    </div>
                    <div class="celebration-container">
                        <div class="bingo-celebration">
                            <span class="letter">B</span>
                            <span class="letter">I</span>
                            <span class="letter">N</span>
                            <span class="letter">G</span>
                            <span class="letter">O</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="games-bar">
                <h2>
                    Recent Games
                </h2>
                <div *ngFor="let game of myGames; let i = index" class="games-bar-item" [class.active]="gameId === game" [class.active-game]="i === 0" (click)="openGame(game)">
                    {{layout.name}} - {{game}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="noGame$ | async">
            <h1 class="text-center">There are no active games.</h1>
        </ng-container>
    </div>

    <div *ngIf="userInfo.accountType > AccountType.Free" class="game-selector">
        <select class="form-control" *ngIf="creatorLayouts$ | async as layouts" [(ngModel)]="selectedCreatorLayout">
            <option *ngFor="let layout of layouts; let i = index" [ngValue]="layout.id">
                {{layout.name}}
            </option>
        </select>
        <button class="btn-v2" (click)="startNewGame()">Start New Game</button>
    </div>
</div>

<!--<div class="main main-full" *ngIf="userInfo.accountType < AccountType.Official">-->
<!--    <h1 class="coming-soon">-->
<!--        Coming Soon!-->
<!--    </h1>-->
<!--</div>-->
