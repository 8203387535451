<div class="main-full">
    <div class="container-fluid browse-container mb-5">

        <div class="row ml-2 mr-2 mb-3">
            <div class="col-12 col-sm-4 col-md-3 col-xxl-2 p-2 py-3 m-0" *ngFor='let m of media'>
                <app-video-thumb-open [media]="m" [recommendationId]="recommendationId"></app-video-thumb-open>
            </div>
        </div>

        <div *ngIf="additionalResults" class="position-relative div-loader">
        </div>

        <div class="ml-2 mr-2 mb-3 mt-3 text-center" *ngIf="message">
            {{message}}
        </div>
    </div>
</div>
