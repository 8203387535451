import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { StorageManagerService } from './storage-manager.service';
import { DeviceModel } from '../models/device-management';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Bowser from "bowser";

@Injectable({
    providedIn: 'root'
})
export class DeviceManagementService {

    private _deviceIdentifier$ = new BehaviorSubject<string>(null);
    get deviceIdentifier$(): Observable<string> {
        return this._deviceIdentifier$.asObservable()
    }

    public get deviceIdentifier(): string {
        return this.storage.overrideDeviceIdentifier ?? this.storage.deviceIdentifier;
    };

    public readonly device: DeviceModel;

    constructor(private env: EnvironmentService,
                private http: HttpClient,
                private storage: StorageManagerService) {
        if (!this.deviceIdentifier) {
            this.storage.deviceIdentifier = crypto.randomUUID();
        }
        const bowser = Bowser.parse(window.navigator.userAgent);
        this.device = {
            deviceIdentifier: this.deviceIdentifier,
            isActive: true,
            platform: 'web',
            model: bowser.os.name + ' ' + bowser.browser.name
        }
        this._deviceIdentifier$.next(this.device.deviceIdentifier);

        this.storage.deviceIdentifierOverride$.subscribe(x => {
            if (!x) {
                return;
            }
            this._deviceIdentifier$.next(x);
        })
    }

    getDevices(): Observable<DeviceModel[]> {
        return this.http.get<DeviceModel[]>(`${this.env.userHost}/v2/device`);
    }

    addDevice(): Observable<any> {
        return this.http.post<any>(`${this.env.userHost}/v2/device`, this.device);
    }

    updateDeviceActive(deviceIdentifier: string, isActive: boolean): Observable<any> {
        return this.http.patch(`${this.env.userHost}/v2/device`, { isActive, deviceIdentifier });
    }

    deleteDevice(deviceIdentifier: string): Observable<any> {
        return this.http.delete(`${this.env.userHost}/v2/device`, { body: { deviceIdentifier } });
    }

    setDeviceIdentifier(deviceIdentifier: string) {
        this._deviceIdentifier$.next(deviceIdentifier);
    }
}
