import { ImageModel } from './image-model';

export interface MediaSummary {
    id?: number;
    mediaId?: number;
    seasonId?: number;
    previewImage?: string;
    title?: string;
    duration?: string;
    subscriptionOnly?: boolean;
    datePublished?: string;
    shortDescription?: string;
    channelImageUrl?: string;
    images?: ImageModel[];
    image?: string;
    channelBadgeUrl?: string;
    livestreamId?: number;
    creator?: string;
    creatorTeamId?: number;
}
