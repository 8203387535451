import { Component, OnInit } from "@angular/core";
import { Toast } from "../../models/toast";
import { animate, style, transition, trigger } from "@angular/animations";
import { UiService } from "../../services/ui.service";

@Component({
    selector: "app-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
    animations: [
        trigger("fadeInOut", [
            transition(":enter", [
                style({ opacity: "0", transform: "translateY(100%)" }),
                animate(
                    300,
                    style({ opacity: "1", transform: "translateY(0)" })
                ),
            ]),
            transition(":leave", [
                animate(
                    300,
                    style({ opacity: "0", transform: "translateY(100%)" })
                ),
            ]),
        ]),
    ],
})
export class ToastComponent implements OnInit {
    toasts: Toast[] = [];

    constructor(
        private ui: UiService
    ) {
    }

    ngOnInit(): void {
        this.ui.displayToast.subscribe((x) => {
            const length = this.toasts.push(x);
            this.toasts[length - 1].timeout = setTimeout(() => {
                this.toasts.splice(this.toasts.findIndex((y) => y.message === x.message && y.type === x.type), 1);
            }, x.delay);
        });
    }

    close(index: number) {
        clearTimeout(this.toasts[index].timeout);
        this.toasts.splice(index, 1);
    }

}
