import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from '../services/account.service';
import { StorageManagerService } from '../services/storage-manager.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {


    constructor(private router: Router,
                private account: AccountService,
                private storage: StorageManagerService) {
    }

    canActivate = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> => {

        let redirect = ['/'];

        if (route.data.type === 'no-auth') {
            return this.account.isAuthenticated() ? of(this.router.createUrlTree(redirect)) : of(true);
        }

        if (!this.account.isAuthenticated()) {
            redirect = ['/login'];
            this.storage.setAfterLoginUrl(state.url);
        }

        if (route.data.type === 'override') {
            const accessToken = route.queryParamMap.get('token');
            const deviceIdentifier = route.queryParamMap.get('deviceIdentifier');
            if (accessToken) {
                this.storage.setOverrideToken(accessToken);
                this.storage.overrideDeviceIdentifier = deviceIdentifier;
                this.account.setAuthenticated(true);
                this.account.isOverride = true;
            }
            return this.account.isAuthenticated() ? of(true) : of(this.router.createUrlTree(redirect));
        }

        return this.account.isAuthenticated() ? of(true) : of(this.router.createUrlTree(redirect));
    };

}
