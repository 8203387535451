import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmartTvService } from 'src/app/services/smart-tv.service';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { AuthenticationOverride } from 'src/app/modules/authentication-override';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-smart-tv',
    templateUrl: './smart-tv.component.html',
    styleUrls: ['./smart-tv.component.scss']
})
export class SmartTvComponent extends AuthenticationOverride implements OnInit, OnDestroy {

    loading: boolean;
    smartCode: any;
    loginCodes = 0;
    loginCodeLimit = 3;

    timerLength: number = 120;
    paused: boolean = false;
    timer: number = 120;
    subscription: Subscription;

    tvResponse: any;
    errorMessage: string;

    isV1 = false;

    constructor(
        private smartService: SmartTvService,
        route: ActivatedRoute,
        storageManager: StorageManagerService,
        router: Router,
        account: AccountService
    ) {
        super(route, storageManager, router, account);

        this.isV1 = environment.name === 'Corridor Digital';
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    async ngOnInit() {
        super.ngOnInit();
        this.timer = this.timerLength;
        this.loading = true;

        let accessToken = this.storageManager.getOverrideToken();
        if (!accessToken || accessToken === 'null') {
            this.account.updateToken().then(() => {
                this.startSmartCode();
            });
        } else {
            this.startSmartCode();
        }
    }

    startSmartCode() {
        this.smartService.getLoginCode().subscribe(res => {
            this.loginCodes++;
            this.smartCode = res;
            this.loading = false;

            this.subscription = interval(1000)
                .subscribe((val) => {
                    this.timer -= 1;
                    if (this.paused === true) {
                        return;
                    }

                    if (this.timer % 5 === 0) {
                        this.smartService.getLoginCodeResponse(this.smartCode.code).subscribe(res => {
                            if (res && res.device) {
                                this.tvResponse = res;
                                this.paused = true;
                            }
                        });
                    }
                    if (this.timer <= 0 && !this.paused) {
                        this.getLoginCode();
                    }
                });
        });
    }

    getLoginCode() {
        if (this.loginCodes >= this.loginCodeLimit) {
            this.errorMessage = 'You have reached your Smart TV code limit. Please refresh the page to continue.';
            this.paused = true;
            this.subscription?.unsubscribe();
            return;
        }

        this.smartService.getLoginCode().subscribe(
            {
                next: res => {
                    this.loginCodes++;
                    console.log(this.loginCodes);
                    this.smartCode = res;
                    this.timer = this.timerLength;
                },
                error: e => {
                    this.paused = true;
                    this.account.checkUnauthorized(e);
                    this.errorMessage = 'An unexpected error occurred. Please refresh the page.';
                    this.subscription?.unsubscribe();
                }
            });
    }

    correctDevice(hash: string) {
        this.smartService.confirm(hash).subscribe(
            () => {
                this.paused = false;
                this.loginCodes = 0;
                if (this.isMobile) {
                    this.onComplete();
                }
                else {
                    Swal.fire('Your Smart TV is now linked.');
                    this.router.navigate(['/account']);
                }
            },
            () => {
                Swal.fire('Pairing failed');
                this.paused = false;
                this.tvResponse = null;
                this.loginCodes = 0;
                this.getLoginCode();
            }
        );
    }

    wrongDevice(hash: string) {
        this.smartService.reject(hash).subscribe({
            next: () => {
                this.paused = false;
                this.tvResponse = null;
                this.loginCodes = 0;
                this.getLoginCode();
            },
            error: () => {
                this.paused = false;
                this.tvResponse = null;
                this.loginCodes = 0;
                this.getLoginCode();
            }
        });
    }
}
