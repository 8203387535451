<div class="chat-container" [class.pop-out]="isPopOut">
    <div class="chat-messages scroll-colour" [scrollTop]="scrollToBottom()" #scrollMe>
        <div *ngFor="let comment of comments">
            <app-chat-read [comment]='comment'></app-chat-read>
        </div>
    </div>
    <div class="chat-input-container">
        <div class="chat-input">
            <input #comment (keyup.enter)="send()" type="text" name="comment" [(ngModel)]="chat.message"
                   #message="ngModel" class="form-control text-input-dark inline-block emoji-padding"
                   placeholder="Something friendly to say?" tabindex="1" autocomplete="off" maxlength="450">
            <img class="smiley-img pointer" (click)="emoji()" src="/assets/smiley.png" height="20px" />
            <img class="send-img pointer" (click)="send()" src="/assets/send.png" height="20px" />

            <emoji-mart *ngIf="customEmojis" (emojiClick)="addEmoji($event)" [darkMode]="true" [custom]="customEmojis" [include]="includes"
                        [i18n]="translations" [style]="{ position: 'absolute', right: '50px', marginTop: '-510px' }"
                        [hidden]="hideEmoji">
            </emoji-mart>
        </div>
    </div>
    <div class="stats-container">
        <div class="viewers">
            {{this.viewers$ | async}}
            <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2421_649)">
                    <mask id="mask0_2421_649" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="512" height="512">
                        <path d="M0 0H512V512H0V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_2421_649)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M258 304C327.588 304 384 247.588 384 178C384 108.412 327.588 52 258 52C188.412 52 132 108.412 132 178C132 247.588 188.412 304 258 304Z" class="svg-colour"/>
                    </g>
                    <mask id="mask1_2421_649" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="512" height="512">
                        <path d="M0 0H512V512H0V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_2421_649)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M258 720C358.516 720 440 638.516 440 538C440 437.484 358.516 356 258 356C157.484 356 76 437.484 76 538C76 638.516 157.484 720 258 720Z" class="svg-colour"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2421_649">
                        <rect width="512" height="512" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>

        <div>
            <label class="form-switch">
                <span class="switch-text">Autoscroll</span>
                <input type="checkbox" [(ngModel)]="autoscroll">
                <i></i>
            </label>
        </div>

        <div>
            <a [href]="'/live-chat/' + livestreamId" target="_blank">Popout chat</a>
        </div>

    </div>
</div>
