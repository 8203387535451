import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { VideoComponent } from './pages/video/video.component';
import { CancelComponent } from './pages/cancel/cancel.component';
import { SuccessComponent } from './pages/success/success.component';
import { ChannelComponent } from './pages/channel/channel.component';
import { SearchComponent } from './pages/search/search.component';
import { SuccessCardUpdateComponent } from './pages/success-card-update/success-card-update.component';
import { UiKitComponent } from './pages/ui-kit/ui-kit.component';
import { ShowsComponent } from './pages/shows/shows.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BaseRerouteComponent } from './pages/base-reroute/base-reroute.component';
import { AccountV2Component } from './pages/account-v2/account-v2/account-v2.component';
import { DiscountCodeComponent } from './pages/account-v2/discount-code/discount-code.component';
import { ChangeEmailV2Component } from './pages/account-v2/change-email-v2/change-email-v2.component';
import { ChangeDisplayNameV2Component } from './pages/account-v2/change-display-name-v2/change-display-name-v2.component';
import { ChangePasswordV2Component } from './pages/account-v2/change-password-v2/change-password-v2.component';
import { EmailPreferencesV2Component } from './pages/account-v2/email-preferences-v2/email-preferences-v2.component';
import { CancelMembershipComponent } from './pages/account-v2/cancel-membership/cancel-membership.component';
import { SmartTvComponent } from './pages/account-v2/smart-tv/smart-tv.component';

import { redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

import { LegalDocumentComponent } from './pages/legal-document/legal-document.component';
import { LegalDocumentsResolver } from './services/resolvers/legal-documents-resolver.service';
import { LegalDocumentsTypesResolver } from './services/resolvers/legal-documents-types-resolver.service';
import { LegalDocumentResolver } from './services/resolvers/legal-document-resolver.service';
import { CountryBlockedComponent } from './components/country-blocked/country-blocked.component';
import { LatestVideosComponent } from './components/latest-videos/latest-videos.component';
import { SeriesViewV2Component } from './pages/series-view-v2/series-view-v2.component';
import {
    CancelMembershipReasonComponent
} from './pages/account-v2/cancel-membership-reason/cancel-membership-reason.component';
import { ChangeLanguageComponent } from './pages/account-v2/change-language/change-language/change-language.component';
import { BlankComponent } from './pages/blank/blank.component';
import { WatchlistComponent } from './pages/watchlist/watchlist.component';
import { PaymentComponent } from './pages/access-flow/payment/payment.component';
import { CompletePaymentComponent } from './pages/access-flow/complete-payment/complete-payment.component';
import { RegisterComponent } from './pages/access-flow/register/register.component';
import { LoginComponent } from './pages/access-flow/login/login.component';
import { ForgottenPasswordComponent } from './pages/access-flow/forgotten-password/forgotten-password.component';
import { ForgottenPasswordRecoveryComponent } from './pages/access-flow/forgotten-password-recovery/forgotten-password-recovery.component';
import { DeveloperPageComponent } from './pages/developer-page/developer-page.component';
import { SomethingWrongComponent } from './pages/access-flow/something-wrong/something-wrong.component';
import { PrivacyKoreanComponent } from './pages/privacy-korean/privacy-korean.component';
import { VoucherV2Component } from './pages/account-v2/voucher-v2/voucher-v2.component';
import { PaymentMethodV2Component } from './pages/account-v2/payment-method-v2/payment-method-v2.component';
import { ProfilePictureV2Component } from './pages/account-v2/profile-picture-v2/profile-picture-v2.component';
import { PaymentHistoryV2Component } from './pages/account-v2/payment-history-v2/payment-history-v2.component';
import { PointHistoryV2Component } from './pages/account-v2/point-history-v2/point-history-v2.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { PaypalPaymentPendingComponent } from './pages/paypal-payment-pending/paypal-payment-pending.component';
import { ChangePlanComponent } from './pages/account-v2/change-plan/change-plan.component';
import { VerifyEmailComponent } from './pages/access-flow/verify-email/verify-email.component';
import { DeleteAccountComponent } from './pages/account-v2/delete-account/delete-account.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { UnderConstructionGuard } from './guards/under-construction.guard';
import { AuthGuard } from './guards/auth.guard';
import { ChangeEmailCompleteComponent } from './pages/account-v2/change-email-complete/change-email-complete.component';
import { SubtitleSettingsComponent } from './pages/account-v2/subtitle-settings/subtitle-settings.component';
import { VideoQualityComponent } from './pages/account-v2/video-quality/video-quality.component';
import { CreatorsComponent } from './pages/creators/creators.component';
import { CreatorProfileComponent } from './pages/creator-profile/creator-profile.component';
import { DeviceManagementComponent } from './pages/account-v2/device-management/device-management.component';
import { BingoComponent } from './pages/bingo/bingo.component';
import { BrowseComponent } from './pages/browse/browse.component';


const authRequiredTV = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    sessionStorage.setItem('afterlogin', state.url);
    return redirectUnauthorizedTo(['login']);
};

const authRequired = () => redirectUnauthorizedTo(['signup']);
const homeNoAuth = () => redirectUnauthorizedTo(['join']);
const noAuth = () => redirectLoggedInTo(['']);

const routes: Routes = [



    // must be outside and above the main bunch to prevent loops
    { path: 'maintenance', component: MaintenanceComponent, canActivate: [UnderConstructionGuard, MaintenanceGuard] },
    { path: 'under-construction', component: UnderConstructionComponent, canActivate: [UnderConstructionGuard] },

    // pages to engage maintenance mode with
    // pages you DON'T want affected must be above this line
    {
        path: '', canActivate: [UnderConstructionGuard, MaintenanceGuard], children: [
            { path: '', component: HomeComponent },
            { path: 'video/:id', component: VideoComponent },
            { path: 'shows', component: ShowsComponent },
            { path: 'shows/:id', component: ShowsComponent },
            { path: 'creators', component: CreatorsComponent },
            { path: 'creators/:creatorId', component: CreatorProfileComponent},
            { path: 'latest-videos', component: LatestVideosComponent },
            { path: 'season/:seasonId', component: SeriesViewV2Component },

            { path: 'privacy-korean', component: PrivacyKoreanComponent },

            // account
            { path: 'account', component: AccountV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/cancel-membership', component: CancelMembershipComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/change-language', component: ChangeLanguageComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/cancel-membership/reason', component: CancelMembershipReasonComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/display-name', component: ChangeDisplayNameV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/email', component: ChangeEmailV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'change-email-confirm', component: ChangeEmailCompleteComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/email/complete', component: ChangeEmailCompleteComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/email-preferences', component: EmailPreferencesV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/payment-history', component: PaymentHistoryV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/payment-method', component: PaymentMethodV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/password', component: ChangePasswordV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/point-history', component: PointHistoryV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/profile-picture', component: ProfilePictureV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/store-code', component: DiscountCodeComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/video-quality', component: VideoQualityComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/voucher', component: VoucherV2Component, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/change-plan', component: ChangePlanComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/smart-code', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/device-management', component: DeviceManagementComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'tv', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'tv-code', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'tv-code/:code', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'watch', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'watch/:code', component: SmartTvComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/delete-account', component: DeleteAccountComponent, canActivate: [AuthGuard], data: {type: 'override'} },
            { path: 'account/subtitle-settings', component: SubtitleSettingsComponent, canActivate: [AuthGuard], data: {type: 'override'} },

            { path: 'browse', component: BrowseComponent, canActivate: [AuthGuard] },

            // both
            { path: 'channel/:id', component: ChannelComponent, },
            { path: 'ui-kit', component: UiKitComponent },
            { path: 'search', component: SearchComponent },
            { path: 'not-found', component: NotFoundComponent },

            // {path: 'greenlight', component: CustomMessageComponent, data: {title: 'Coming Soon!', message: 'Greenlight is being redesigned! Watch this space.'}},

            { path: 'something-went-wrong', component: SomethingWrongComponent },

            { path: 'developer', component: DeveloperPageComponent },

            // public
            { path: 'forgotten-password', component: ForgottenPasswordComponent, canActivate: [AuthGuard], data: { type: 'no-auth' } },
            { path: 'forgotten-password-recovery', component: ForgottenPasswordRecoveryComponent },
            { path: 'login', component: LoginComponent, canActivate: [AuthGuard], data: { type: 'no-auth' } },
            { path: 'signup', component: RegisterComponent, canActivate: [AuthGuard], data: { type: 'no-auth' }, resolve: { documentData: LegalDocumentsResolver, documentTypeData: LegalDocumentsTypesResolver } },
            { path: 'signup-tv', component: RegisterComponent, canActivate: [AuthGuard], data: { type: 'no-auth', isTVSignup: true }, resolve: { documentData: LegalDocumentsResolver, documentTypeData: LegalDocumentsTypesResolver } },
            { path: 'subscribe', component: RegisterComponent, canActivate: [AuthGuard], data: { type: 'no-auth' }, resolve: { documentData: LegalDocumentsResolver, documentTypeData: LegalDocumentsTypesResolver } },
            { path: 'register', component: LoginComponent, canActivate: [AuthGuard], data: { type: 'no-auth' } },
            { path: 'verify', component: VerifyEmailComponent },
            { path: 'verify/:code', component: VerifyEmailComponent },
            { path: 'country-blocked', component: CountryBlockedComponent },
            { path: 'legaldocuments/:type', component: LegalDocumentComponent, resolve: { documentData: LegalDocumentResolver, documentTypeData: LegalDocumentsTypesResolver } },
            { path: 'payment', component: PaymentComponent },
            { path: 'payment/paypal', component: PaypalPaymentPendingComponent },
            { path: 'complete-payment/:priceId', component: CompletePaymentComponent },

            { path: 'privacy', redirectTo: '/legaldocuments/privacy-policy', pathMatch: 'full' },
            { path: 'privacy-policy', redirectTo: '/legaldocuments/privacy-policy', pathMatch: 'full' },
            { path: 'terms', redirectTo: '/legaldocuments/terms-and-conditions', pathMatch: 'full' },
            { path: 'terms-and-conditions', redirectTo: '/legaldocuments/terms-and-conditions', pathMatch: 'full' },
            { path: 'refund', redirectTo: '/legaldocuments/refund-policy', pathMatch: 'full' },
            { path: 'refund-policy', redirectTo: '/legaldocuments/refund-policy', pathMatch: 'full' },

            // private
            { path: 'cancel', component: CancelComponent, canActivate: [AuthGuard] },
            { path: 'success', component: SuccessComponent, canActivate: [AuthGuard] },
            { path: 'success-card-update', component: SuccessCardUpdateComponent, canActivate: [AuthGuard] },
            { path: 'email-preferences', component: EmailPreferencesV2Component, canActivate: [AuthGuard] },
            { path: 'watchlist', component: WatchlistComponent, canActivate: [AuthGuard] },

            { path: 'contact', redirectTo: '/home/feedback', pathMatch: 'full' },
            { path: 'feedback', redirectTo: '/home/feedback', pathMatch: 'full' },

            { path: 'home', loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule) },

            // used for reloading video
            { path: 'blank', component: BlankComponent },

            { path: ':id', component: BaseRerouteComponent },

        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
