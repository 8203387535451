<h2 class="title">{{ "account" | translate }}</h2>

<div class="account-grid">

    <!-- Profile -->
    <div class="account-grid-item">
        <div class="account-grid-item-title">
            Profile
        </div>
        <div class="account-grid-item-body">
            <ng-container *ngIf="!loading">
                <div class="d-flex justify-content-between align-items-center font-weight-bold mb-2">
                    <div>Display Name</div>
                    <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/display-name']">
                        Change
                    </a>
                </div>
                <div class="overflow-hidden text-overflow-ellipsis">
                    {{ displayName }}
                </div>

                <div class="account-grid-item-body-divider"></div>

                <div class="d-flex justify-content-between align-items-center font-weight-bold mb-2">
                    <div>Email</div>
                    <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/email']">
                        Change
                    </a>
                </div>
                <div class="overflow-hidden text-overflow-ellipsis">
                    {{ email }}
                </div>

                <div class="account-grid-item-body-divider"></div>

                <div class="d-flex justify-content-between align-items-center font-weight-bold mb-2">
                    <div>Password</div>
                    <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/password']">
                        Change
                    </a>
                </div>
                <div class="overflow-hidden text-overflow-ellipsis">
                    ********
                </div>

                <div class="account-grid-item-body-divider"></div>
                <div class="account-grid-list-item">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/profile-picture']">
                            {{ "account-change-profile-picture" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve"
                           [routerLink]="['/account/subtitle-settings']">
                            Subtitle Settings
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/video-quality']">
                            Video Quality
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/email-preferences']">
                            {{ "account-email-preferences" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="config.remoteConfig.badges_enabled">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/badges']">
                            {{ "account-badges" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="config.remoteConfig.store_discount_enabled">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/store-code']">
                            {{ "account-store-discount-code" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/smart-code']">
                            {{ "account-smart-tv-login-code" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="config.remoteConfig.device_management_enabled">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/device-management']">
                            Device Management
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="appParam !== 'ios' && config.remoteConfig.vouchers_enabled">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/voucher']">
                            {{ "account-voucher-code" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item"
                     *ngIf="!isNullOrWhitespace(config.remoteConfig.discord_invite) && hasSubscription">
                    <div>
                        <a class="account-link" href="{{config.remoteConfig.discord_invite}}" [target]="isMobile ? '_self' : '_blank'">
                            Discord Invite
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="isMobile">
                    <div>
                        <a class="account-link pointer" queryParamsHandling="preserve" (click)="logout()">
                            {{ "sign-out" | translate }}
                        </a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Downloads -->
    <div class="account-grid-item" *ngIf="downloadsEnabled">
        <div class="account-grid-item-title">
            Downloads
        </div>

        <ng-container *ngIf="!downloadsLoading">

            <div class="account-grid-list-item">
                <div>
                    <label class="form-switch w-100">
                        <span class="switch-text">Download over Wi-Fi only</span>
                        <input type="checkbox" [(ngModel)]="downloadOverWifi"
                               (ngModelChange)="updateDownloadOverWifi($event)">
                        <i class="float-right"></i>
                    </label>
                </div>
            </div>

            <div class="account-grid-list-item">
                <div>
                    <button class="btn btn-link btn-link-slim d-block text-right scaled account-link"
                            (click)="downloadRedirectMobile()">
                        Go To Downloads
                    </button>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="downloadsLoading">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </ng-container>
    </div>

    <!-- Subscription Details -->
    <div class="account-grid-item">
        <div class="account-grid-item-title">
            {{ "account-subscription-details" | translate }}
        </div>
        <div class="account-grid-item-body">
            <ng-container *ngIf="!loading">
                <div class="account-grid-list-item">
                    <div>{{ plan }}</div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <span *ngIf="hasSubscription === false" class="d-flex align-items-center">
                            {{ "account-subscription-none" | translate }}
                            <button class="btn btn-link btn-link-slim scaled ml-1"
                                    (click)="subscribe()">{{ "account-subscription-now" | translate }}</button>
                        </span>
                        <span *ngIf="subscriptionEndDate && subscriptionCancelled === false">
                            {{ "account-subscription-next-billing-date" | translate }} {{ subscriptionEndDate | date }}
                        </span>
                        <span *ngIf="subscriptionEndDate && subscriptionCancelled === true">
                            {{ "account-subscription-expiry-date" | translate }} {{ subscriptionEndDate | date }}
                        </span>
                        <a class="d-block" *ngIf="hasSubscription && hasPatreon" queryParamsHandling="preserve"
                           [routerLink]="['/payment']">
                            {{ "convert-your-patreon-subscription" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="!config.remoteConfig.stripe_only">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve"
                           [routerLink]="['/account/payment-method']">
                            {{ "account-payment-method" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item" *ngIf="!config.remoteConfig.stripe_only">
                    <div>
                        <a class="account-link" queryParamsHandling="preserve"
                           [routerLink]="['/account/payment-history']">
                            {{ "account-payment-history" | translate }}
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div *ngIf="subscriptionType === 'Stripe'">
                        <a class="account-link" [href]="config.remoteConfig.stripe_portal_link"
                           [target]="isMobile ? '_self' : '_blank'">
                            Manage Subscription
                        </a>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div>
                        <a *ngIf="!subscriptionLoading && subscriptionEndDate && subscriptionCancelled === false && subscriptionType !== 'Voucher'"
                           class="account-link" queryParamsHandling="preserve"
                           [routerLink]="['/account/cancel-membership']">
                            {{ "account-cancel-membership" | translate }}
                        </a>
                        <button *ngIf="!subscriptionLoading && subscriptionEndDate && subscriptionCancelled === true"
                                class="btn btn-link btn-link-slim blue d-block text-right scaled account-link"
                                (click)="restartSubscription()">
                            {{ "account-restart-membership" | translate }}
                        </button>
                        <a *ngIf="!subscriptionLoading && subscriptionEndDate && subscriptionCancelled === false && canUpdatePlan && !config.remoteConfig.stripe_only"
                           class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/change-plan']">
                            {{ "account-change-plan" | translate }}
                        </a>
                    </div>
                    <div class="account-grid-list-item" *ngIf="appParam === 'ios' && config.remoteConfig.restore_apple_purchases_enabled">
                        <div>
                            <a class="account-link pointer" queryParamsHandling="preserve" (click)="restoreApplePurchases()">
                                Restore Apple Purchases
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="loading || subscriptionLoading">
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Project Points -->
    <div class="account-grid-item" *ngIf="config.remoteConfig.points_enabled">
        <div class="account-grid-item-title">
            {{ "project-points" | translate }}
        </div>
        <div class="account-grid-item-body">
            <ng-container *ngIf="!loading">
                <div class="account-grid-list-item">
                    <div class="d-flex align-items-center" *ngIf="!pointsLoading">
                        <span class="mr-1">{{ "you-have" | translate }} {{ producerPoints }}</span>
                        <img class="icon" src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points"/>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <div class="d-flex align-items-center" *ngIf="!pointsLoading">
                        <span class="mr-1">{{ "account-points-since-signup" | translate }} {{ producerPointsAllTime }}
                            {{ "projects-points" | translate }}</span>
                        <img class="icon" src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points"/>
                    </div>
                </div>
                <div class="account-grid-list-item">
                    <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/point-history']">{{
                            "account-point-history" | translate
                        }}</a>
                </div>
                <div class="account-grid-list-item">
                    <button class="btn btn-link btn-link-slim blue scaled account-link" (click)="recalculatePP()">{{
                            "account-recalculate-pp" | translate
                        }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="loading || pointsLoading">
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Notifications -->
    <div class="account-grid-item" *ngIf="config.remoteConfig.creators_enabled">
        <div class="account-grid-item-title">`
            Notifications
        </div>
        <div *ngIf="!loading" class="account-grid-item-body">
            <div class="dropdown-select dropdown-toggle bordered" (click)="openDropdown('notification')" (clickOutside)="notificationsOptionsOpen$.next(false)">
                <div>
                    <span class="dropdown-current-selection">{{ notificationChosen }}</span>
                </div>
                <div class="dropdown" *ngIf="notificationsOptionsOpen$ | async">
                    <div class="dropdown-option" [ngClass]="{'active': type === notificationChosen}" (click)="optionClicked($event, type.value, 'notification')" *ngFor="let type of notificationOptions">
                        {{ type.label }}
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="loading">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </ng-container>
    </div>

    <!-- Games -->
    <div class="account-grid-item" *ngIf="config.remoteConfig.games_enabled">
        <div class="account-grid-item-title">
            Games
        </div>
        <div class="account-grid-item-body">
            <div class="account-grid-list-item" *ngIf="config.remoteConfig.games_bingo_enabled">
                <a class="account-link" [routerLink]="['/bingo']"
                   [target]="isMobile ? '_self' : '_blank'">Bingo</a>
            </div>
        </div>
    </div>

    <!-- Need Help? -->
    <div class="account-grid-item">
        <div class="account-grid-item-title">
            {{ "need-help" | translate }}
        </div>
        <div class="account-grid-item-body">
            <div class="account-grid-list-item" *ngIf="config.remoteConfig.faq_url">
                <a class="account-link" href="{{config.remoteConfig.faq_url}}"
                   [target]="isMobile ? '_self' : '_blank'">{{ "account-faq-knowledge-base" | translate }}</a>
            </div>
            <div class="account-grid-list-item">
                <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/home/feedback']">{{
                        "account-contact-support" | translate
                    }}</a>
            </div>
            <div class="account-grid-list-item">
                <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/account/delete-account']">Delete
                    Account</a>
            </div>
        </div>
    </div>

    <!-- Legal Documents -->
    <div class="account-grid-item">
        <div class="account-grid-item-title">
            Legal Documents
        </div>
        <div class="account-grid-item-body">
            <div class="account-grid-list-item">
                <a class="account-link" queryParamsHandling="preserve"
                   [routerLink]="['/legaldocuments/terms-and-conditions']" [target]="isMobile ? '_self' : '_blank'">
                    Terms and Conditions
                </a>
            </div>
            <div class="account-grid-list-item">
                <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/legaldocuments/privacy-policy']"
                   [target]="isMobile ? '_self' : '_blank'">
                    Privacy Policy
                </a>
            </div>
            <div class="account-grid-list-item">
                <a class="account-link" queryParamsHandling="preserve" [routerLink]="['/legaldocuments/refund-policy']"
                   [target]="isMobile ? '_self' : '_blank'">
                    Refund Policy
                </a>
            </div>
            <hr>
            <div class="account-grid-list-item">
                <p class="recaptcha-docs">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy"><b>Privacy Policy</b></a> and
                    <a href="https://policies.google.com/terms"><b>Terms of Service</b></a> apply.
                </p>
            </div>
        </div>
    </div>

    <div class="app-version">
        Version: {{ app.version }}
    </div>
</div>
