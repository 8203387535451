<div class="thumb-container">
    <img *ngIf="newVideo && !watched" class="thumb-new" src="{{config.remoteConfig.new_badge}}" alt="New Video" />
    <img *ngIf="watched" class="thumb-watched" src="{{config.remoteConfig.watched}}" alt="New Video" />
    <div class="thumb-image-container">

        <a class="thumb-image">
            <a class="thumb-link" [routerLink]="['/video', media.mediaId]">
                <picture (deferLoad)="load()">
                    <source *ngIf="loadImages && this.media && this.media.image" srcset="{{this.media.image + '?w=400'}}">
                    <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                         alt="Loading Image"/>
                </picture>
            </a>
        </a>

        <a class="thumb-hover-image" [routerLink]="['/video', media.mediaId]" [queryParams]="{recommendationId: recommendationId}">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && this.media && this.media.previewImage" srcset="{{this.media.previewImage}}"
                        type="image/webp">
                <source *ngIf="loadImages && this.media && this.media.image" srcset="{{this.media.image + '?w=400'}}">
                <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                     alt="{{media.title}} Image"/>
            </picture>
            <div class="media-duration" *ngIf="media.duration">
                {{media.duration}}
            </div>
        </a>

    </div>

    <ngb-progressbar type="primary" [value]="progress" [max]="100" height="2px" class="thumb-open-progress-bar watch_progress_colour"></ngb-progressbar>

    <a class="thumb-lower-container" [routerLink]="['/video', media.mediaId]" [queryParams]="{recommendationId: recommendationId}">
        <img class="channel-badge" *ngIf="media.channelBadgeUrl"
             src="{{media.channelBadgeUrl}}" alt="Channel Badge"/>

        <div class="thumb-text-container">
            <div class="thumb-text" [ngbTooltip]="media.title" [openDelay]="1000" [placement]="'bottom'">
                {{media.title}}
            </div>
            <div class="additional-info">
                {{media.creator}} <br>
                {{timeAgo}}
            </div>
        </div>

        <div class="more">
            <button class="more-btn" (click)="showMore$.next(!showMore$.value); $event.stopPropagation(); $event.preventDefault()" (clickOutside)="showMore$.next(false)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                <div class="more-dropdown" *ngIf="showMore$ | async">
                    <button class="more-option" (click)="togglePlaylist()">{{existsInPlaylist() ? 'Remove from Watchlist' : 'Add to Watchlist'}}</button>
                    <button class="more-option" *ngIf="follows$ | async as follows" (click)="toggleFollow(follows.includes(media.creatorTeamId), media.creatorTeamId)">{{follows.includes(media.creatorTeamId) ? 'Unfollow' : 'Follow'}} {{media.creator}}</button>
                </div>
            </button>

        </div>
    </a>
    <div class="media-duration" *ngIf="media.duration">
        {{media.duration}}
    </div>
</div>
