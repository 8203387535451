import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { MediaSummary } from '../models';
import { Recommendationsv6 } from '../models/recommendations.v6';

@Injectable({
    providedIn: 'root'
})
export class RecommendationsService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getContentRecommendationsV5(mediaId: number, seasonId: number, order: string): Observable<MediaSummary[]> {
        return this.http
            .get<MediaSummary[]>(this.envService.contentCdnHost + '/v6/recommendations/' + mediaId + "?seasonId=" + seasonId + "&order=" + order)
    }

    getUpNext(mediaId: number, count: number = 20, recommendationId: string = undefined): Observable<Recommendationsv6> {
        let params = new HttpParams()
            .set('count', count);

        if (recommendationId) {
            params = params.set('recommendationId', recommendationId);
        }

        return this.http.get<Recommendationsv6>(`${this.envService.contentHost}/v6/recommendations/up-next/${mediaId}`, {params});
    }

    getRecommendations(type: string, count: number = 20, recommendationId: string = undefined): Observable<Recommendationsv6> {
        let params = new HttpParams()
            .set('count', count);

        if (recommendationId) {
            params = params.set('recommendationId', recommendationId);
        }

        return this.http.get<Recommendationsv6>(`${this.envService.contentHost}/v6/recommendations/${type}`, {params});
    }
}
