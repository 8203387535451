
<div [class.video-page-container]="!theatre" [class.standard-placeholder]="!theatre" [class.theatre-placeholder]="theatre" class="p-0">
    <ng-container *ngIf="combined$ | async as data">
        <shaka-player *ngIf="data.video.dashUrl || data.video.fpsHlsUrl" [url]="data.video.dashUrl" [previewsUrl]="data.video.previewsUrl" [widevineUrl]="data.video.widevineUrl"
                      [fairplayUrl]="data.video.fairPlayUrl" [fpsHlsUrl]="data.video.fpsHlsUrl" [poster]="data.video.poster"
                      [videoId]="data.video.dashVideoId ?? data.video.videoId" [uid]="data.video.uid" [startTimeMs]="data.video.startTimeMs"
                      [textTracks]="data.video.subtitles" (reportMedia)="showReportReasons = true" (theatre)="theatre = $event" [maxBitrate]="data.bitrate">
        </shaka-player>
<!--        <videojs *ngIf="(safari === true && data.video.hlsUrl) || (data.video.hlsUrl && !data.video.dashUrl)" [url]="data.video.hlsUrl" [poster]="data.video.poster"-->
<!--                 [videoId]="data.video.hlsVideoId ?? data.video.videoId" [type]="data.video.type" [autoplay]="autoPlay"-->
<!--                 [startTimeMs]="data.video.startTimeMs"></videojs>-->
        <div *ngIf="(safari === true && data.video.hlsUrl) || (data.video.hlsUrl && !data.video.dashUrl)" class="video-broken">
            <h3>This video needs re-encoding! We're working hard to update our video library but may have missed this one. Please <a [routerLink]="['/contact']" class="underline">contact us</a> letting us know that "Video {{mediaId}}" doesn't work.</h3>
        </div>
    </ng-container>
    <ng-container *ngIf="deviceLimitError">
        <div class="device-limit-error">
            <div class="error-message">
                This device is not activated or you have reached your device limit for streaming video.
            </div>
            <a class="btn btn-outline-light" *ngIf="accountService.isAuthenticated()" [routerLink]="['/account/device-management']">
                Go To Device Management
            </a>
        </div>
    </ng-container>
</div>

<app-modal-media-report *ngIf="showReportReasons" (close)="showReportReasons = false" [mediaId]="mediaId"></app-modal-media-report>
