<div class='video-content'>

    <video *ngIf="audioOnlyOption === false && autoplay === true" id="video" class="video-js vjs-default-skin vjs-16-9"
        playsinline controls preload="auto" autoplay poster="{{poster}}">
    </video>
    <video *ngIf="audioOnlyOption === true && autoplay === true" id="video" class="video-js vjs-default-skin vjs-16-9"
        poster="https://cdn.watchcorridor.com/assets/podcast-audio-bg-img.jpg" controls preload="auto" autoplay>
    </video>

    <div class="video-countdown" *ngIf="autoplayNextVideo == true && autoplay && timeLeft < 20">
        <div class="video-countdown-text">
            Next video in<br />
            <span class="video-countdown-time">{{timeLeft | number: '1.0-0'}}</span>
        </div>
    </div>
</div>
<div *ngIf="audioOnlyOption">
    <button *ngIf="audioPlayback === false" class="btn btn-primary" (click)="toggleVideo()">VIDEO OFF</button>
    <button *ngIf="audioPlayback === true" class="btn btn-primary" (click)="toggleVideo()">VIDEO ON</button>
</div>
