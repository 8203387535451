<div class="main main-full config-background-2">

	<div class="container h-100">
		<div class="entry-content position-relative">
			<div *ngIf="config.remoteConfig.contact_version == 1">
				<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="mt-5">
					<h2 class="form-title">{{ "account-home-feedback-contact-us" | translate }}</h2>

					<div *ngIf="config.remoteConfig.faq_url && config.remoteConfig.faq_url.length > 0">
						<div>{{ "account-home-feedback-contact-us-text" | translate }}</div>
						<a href="{{config.remoteConfig.faq_url}}" [target]="isMobile ? '_self' : '_blank'">{{
							"account-home-feedback-check-faq" | translate }}</a>
					</div>

					<div class="mt-4">
						<p>{{ "account-home-feedback-select" | translate }}</p>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'website'"
                             (click)="onSelected('website')" (keyup.enter)="onSelected('website')"
                             [tabindex]="1">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">{{ "account-home-feedback-website" | translate }}</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'store'" *ngIf="config.remoteConfig.store_contact"
                             (click)="onSelected('store')" (keyup.enter)="onSelected('store')"
                             [tabindex]="2">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">{{ "account-home-feedback-store" | translate }}</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'business'"
                             (click)="onSelected('business')" (keyup.enter)="onSelected('business')"
                             [tabindex]="3">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">{{ "account-home-feedback-business" | translate }}</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'copyright'"
                             (click)="onSelected('copyright')" (keyup.enter)="onSelected('copyright')"
                             [tabindex]="4">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">{{ "account-home-feedback-copyright" | translate }}</div>
                        </div>
					</div>

					<br />

					<div *ngIf="selected == 'store'">
						<p>{{ "account-home-feedback-store-info" | translate }}
                            <a href="mailto:support@bunkerbranding.com" [target]="isMobile ? '_self' : '_blank'">support@bunkerbranding.com</a>
						</p>
					</div>

					<div *ngIf="selected == 'website' || selected == 'business'|| selected == 'copyright'">
						<p *ngIf="selected == 'copyright'">{{ "account-home-feedback-copyright-text" | translate }}</p>
						<div class="form-group">
							<label>{{ "email-address" | translate }}</label>
							<input #emailInput type="text" formControlName="email" class="form-control" autoFocus
								placeholder="{{ 'email-placeholder' | translate }}" tabindex="1"
								[ngClass]="{ 'is-invalid': submitted && contactForm.controls.email.errors }" />
							<div *ngIf="submitted && contactForm.controls.email.errors" class="text-danger">
								<div *ngIf="contactForm.controls.email.errors.required">{{ "email-required" | translate
									}}</div>
								<div *ngIf="contactForm.controls.email.errors.email">{{ "email-address-not-valid" | translate }}
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>{{ "name" | translate }}</label>
							<input type="text" formControlName="name" class="form-control"
								placeholder="{{ 'name-placeholder' | translate }}" tabindex="2"
								[ngClass]="{ 'is-invalid': submitted && contactForm.controls.name.errors }" />
							<div *ngIf="submitted && contactForm.controls.name.errors" class="text-danger">
								<div>{{ "name-required" | translate }}</div>
							</div>
						</div>

						<div class="form-group">
							<label>{{ "subject" | translate }}</label>
							<input type="text" formControlName="subject" class="form-control"
								placeholder="{{ 'subject' | translate }}" tabindex="2"
								[ngClass]="{ 'is-invalid': submitted && contactForm.controls.subject.errors }" />
							<div *ngIf="submitted && contactForm.controls.subject.errors" class="text-danger">
								<div>{{ "subject-required" | translate }}</div>
							</div>
						</div>

						<div class="form-group" *ngIf="selected !== 'copyright'">
							<label>{{ "message" | translate }}</label>
							<textarea formControlName="message" class="form-control minh-100px"
								placeholder="{{ 'message-placeholder' | translate }}" tabindex="3"
								[ngClass]="{ 'is-invalid': submitted && contactForm.controls.message.errors }">
                            </textarea>
							<div *ngIf="submitted && contactForm.controls.message.errors" class="text-danger">
								<div>{{ "message-required" | translate }}</div>
							</div>
						</div>

						<div class="form-group" *ngIf="selected == 'copyright'">
							<label>{{ "urls-copyright-offending-videos" | translate }}</label>
							<textarea formControlName="message" class="form-control minh-100px" placeholder="Urls"
								tabindex="3"
								[ngClass]="{ 'is-invalid': submitted && contactForm.controls.message.errors }">
                            </textarea>
							<div *ngIf="submitted && contactForm.controls.message.errors" class="text-danger">
								<div>{{ "urls-copyright-offending-videos-required" | translate }}</div>
							</div>
						</div>

						<p class="red-text">{{ errorMessage }}</p>

						<div class="form-group">
							<button type="submit" tabindex="3" class="btn btn-lg btn-primary btn-block">{{ "submit" | translate }}</button>
						</div>
					</div>
				</form>
			</div>

            <div *ngIf="config.remoteConfig.contact_version == 2">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="mt-5">
                    <h2 class="form-title">{{ "account-home-feedback-contact-us" | translate }}</h2>

                    <div class="mt-4">
                        <p>Select Who You'd Like to Contact</p>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'customer-support'"
                             (click)="onSelected('customer-support')" (keyup.enter)="onSelected('customer-support')"
                             [tabindex]="1">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">Customer Support</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'store'"
                             (click)="onSelected('store')" (keyup.enter)="onSelected('store')"
                             [tabindex]="2" *ngIf="config.remoteConfig.store_contact">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">{{ "account-home-feedback-store" | translate }}</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'member-feedback'"
                             (click)="onSelected('member-feedback')" (keyup.enter)="onSelected('member-feedback')"
                             [tabindex]="3">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">Member Feedback</div>
                        </div>

                        <div class="checkbox-container mb-2" [class.active]="selected === 'other'"
                             (click)="onSelected('other')" (keyup.enter)="onSelected('other')"
                             [tabindex]="4">
                            <div class="checkbox"></div>
                            <div class="checkbox-label">Other Inquiry</div>
                        </div>
                    </div>

                    <br />

                    <div *ngIf="selected == 'store'">
                        <p>{{ "account-home-feedback-store-info" | translate }}
                            <a href="mailto:support@bunkerbranding.com" [target]="isMobile ? '_self' : '_blank'">support@bunkerbranding.com</a>
                        </p>
                    </div>

                    <div *ngIf="selected && selected != 'store'">
                        <div class="form-group">
                            <label>{{ "email-address" | translate }}</label>
                            <input #emailInput type="text" formControlName="email" class="form-control" autoFocus
                                   placeholder="{{ 'email-placeholder' | translate }}" tabindex="1"
                                   [ngClass]="{ 'is-invalid': submitted && contactForm.controls.email.errors }" />
                            <div *ngIf="submitted && contactForm.controls.email.errors" class="text-danger">
                                <div *ngIf="contactForm.controls.email.errors.required">{{ "email-required" | translate
                                    }}</div>
                                <div *ngIf="contactForm.controls.email.errors.email">{{ "email-address-not-valid" | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ "name" | translate }}</label>
                            <input type="text" formControlName="name" class="form-control"
                                   placeholder="{{ 'name-placeholder' | translate }}" tabindex="2"
                                   [ngClass]="{ 'is-invalid': submitted && contactForm.controls.name.errors }" />
                            <div *ngIf="submitted && contactForm.controls.name.errors" class="text-danger">
                                <div>{{ "name-required" | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ "subject" | translate }}</label>
                            <input type="text" formControlName="subject" class="form-control"
                                   placeholder="{{ 'subject' | translate }}" tabindex="2"
                                   [ngClass]="{ 'is-invalid': submitted && contactForm.controls.subject.errors }" />
                            <div *ngIf="submitted && contactForm.controls.subject.errors" class="text-danger">
                                <div>{{ "subject-required" | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ "message" | translate }}</label>
                            <textarea formControlName="message" class="form-control minh-100px"
                                      placeholder="{{ 'message-placeholder' | translate }}" tabindex="3"
                                      [ngClass]="{ 'is-invalid': submitted && contactForm.controls.message.errors }">
                            </textarea>
                            <div *ngIf="submitted && contactForm.controls.message.errors" class="text-danger">
                                <div>{{ "message-required" | translate }}</div>
                            </div>
                        </div>

                        <p class="red-text">{{ errorMessage }}</p>

                        <div class="form-group">
                            <button type="submit" tabindex="3" class="btn btn-lg btn-primary btn-block">{{ "submit" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>

			<div *ngIf="config.remoteConfig.stv_login_enabled && config.remoteConfig.show_stv_platforms">
				<h5>Smart TV Platforms</h5>
				<div *ngIf="config.remoteConfig.stv_fire_url && config.remoteConfig.stv_fire_url.length > 0">
                    Amazon FireTV: <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_fire_url}}">App Store</a>
                </div>
				<div *ngIf="config.remoteConfig.stv_android_url && config.remoteConfig.stv_android_url.length > 0">
                    Android TV: <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_android_url}}">App Store</a>
                </div>
				<div *ngIf="config.remoteConfig.stv_tvos_url && config.remoteConfig.stv_tvos_url.length > 0">
                    tvOS (Apple): <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_tvos_url}}">App Store</a>
                </div>
				<div *ngIf="config.remoteConfig.stv_samsung_url && config.remoteConfig.stv_samsung_url.length > 0">
                    Samsung TV: <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_samsung_url}}">App Store</a>
                </div>
				<div *ngIf="config.remoteConfig.stv_lg_url && config.remoteConfig.stv_lg_url.length > 0">
                    LG TV: <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_lg_url}}">App Store</a>
                </div>
				<div *ngIf="config.remoteConfig.stv_roku_url && config.remoteConfig.stv_roku_url.length > 0">
                    Roku: <a [target]="isMobile ? '_self' : '_blank'" href="{{config.remoteConfig.stv_roku_url}}">App Store</a>
                </div>
			</div>
		</div>
	</div>
</div>
