import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StreamReport, VideoResponse } from '../models';
import { EnvironmentService } from './environment.service';
import { VideoReportModel } from '../models/video-report-model';
import { DeviceManagementService } from './device-management.service';

@Injectable({
    providedIn: 'root'
})
export class VideoService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
        private deviceManager: DeviceManagementService) {
    }

    getVideo(mediaId: number, platform: string, recommendationId: string = undefined): Observable<VideoResponse> {
        const headers = new HttpHeaders({
            'device-identifier': this.deviceManager.deviceIdentifier
        });

        let params = new HttpParams();
        if (recommendationId) {
            params = params.set('recommendationId', recommendationId);
        }

        return this.http.get<VideoResponse>(this.envService.contentHost + '/v5/video/' + mediaId + '?platform=' + platform, { headers, params })
    }

    getFreeVideo(mediaId: number, platform: string): Observable<VideoResponse> {
        const headers = new HttpHeaders({
            'device-identifier': this.deviceManager.deviceIdentifier
        });
        return this.http.get<VideoResponse>(this.envService.contentHost + '/v5/video/' + mediaId + '/free?platform=' + platform, { headers })
    }

    reportStreamProgress(uid: string, videoId: number, streamReport: StreamReport): Observable<any> {
        return this.http.post<any>(this.envService.userHost + '/v1/videoReport/' + uid + '?platform=Web&videoId=' + videoId, streamReport)
    }

    reportMedia(mediaId: number, report: VideoReportModel): Observable<any> {
        return this.http.post<any>(this.envService.contentHost + '/v5/video/' + mediaId + '/report', report);
    }

    getReportReasons(mediaId: number): Observable<{ [key: string]: string; }> {
        return this.http.get<{ [key: string]: string; }>(this.envService.contentHost + '/v5/video/' + mediaId + '/report');
    }
}
