import { Component, OnDestroy, OnInit } from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import {LiveService} from 'src/app/services/live.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInfoService } from '../../services/user-info.service';
import { Livestream } from '../../models/socket-messages/livestream-status';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit, OnDestroy {

    url: string;
    livestreamId: number;
    closeSub$: Subject<void> = new Subject<void>();
    name = environment.name;
    hasNextStream = false;
    statusUpdated = false;
    isLive = false;

    destroy$: Subject<void> = new Subject<void>();

    constructor(private liveService: LiveService,
                private route: ActivatedRoute,
                private router: Router,
                private userInfo: UserInfoService) {
    }

    ngOnInit() {
        const streamId = this.route.snapshot.paramMap.get('id');
        if (streamId) {
            this.livestreamId = +streamId;
        }

        this.liveService.status$.pipe(takeUntil(this.destroy$)).subscribe(status => {
            this.isLive = status.isLive;
        })

        this.liveService.status$.pipe(takeUntil(this.closeSub$)).subscribe(status => {
            if (!status) {
                return;
            }

            this.statusUpdated = true;

            if (!status.isLive) {
                this.hasNextStream = status.nextStream !== undefined && status.nextStream !== null;
            }

            if (this.livestreamId && status.streams && status.streams.length > 0) {
                let stream = status.streams.find(x => x.livestreamId === this.livestreamId)

                if (!stream) {
                    stream = status.streams[0];
                }

                if (!this.checkSubscription(stream))
                    return;

                this.url = stream.url;
                this.closeSub$.next();

                return;
            }

            if (!this.livestreamId && status.streams && status.streams.length > 0 && status.isLive) {
                let stream = status.streams[0];

                if (!this.checkSubscription(stream))
                    return;

                this.url = stream.url;
                this.livestreamId = stream.livestreamId;
                this.closeSub$.next();
                return;
            }
        });
        this.liveService.requestStatus();
    }

    ngOnDestroy() {
        this.closeSub$.next();
        this.closeSub$.complete();
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkSubscription(stream: Livestream) {
        if (stream.requiresSubscription) {
            if (!this.userInfo.hasSubscription) {
                this.router.navigate(['/payment']);
                return false;
            }
        }
        return true;
    }
}
