import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnInit, Output,
    ViewChild
} from '@angular/core';
import { RemoteConfigService } from '../../services/remote-config.service';


@Component({
    selector: 'app-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, AfterViewInit {

    numberOfTransforms = 0;
    numberOfItems = 5;
    transform;
    flex;
    width;
    left = false;
    right = true;
    first;
    last;
    prevTaken: number;
    prevNumberItems: number;

    taken = 0;

    @Input() rows = 1;
    @Input() disableNextRow = false;
    @Input() slim = false;
    @Input() disableHover = false;
    @Input() type: string;
    @Input() channelId: string | number;
    @Input() thumbnailType: string;
    @Input() items: any[];
    @Input() displayItems: any[] = [];
    @Input() watchList: boolean;
    @Output() maxWidth: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('imageSlider') containerRef: ElementRef;

    constructor(private cdr: ChangeDetectorRef,
        public config: RemoteConfigService) {
        cdr.detach();
    }

    ngOnInit(): void {
        this.transform = '';
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
        this.calculateSliderSize();
        this.cdr.detectChanges();
        this.cdr.reattach();
    }

    onResize(event) {
        this.calculateSliderSize();
    }

    private calculateSliderSize() {
        let width;
        let pageWidth;
        let thumbnailWidth;
        if (this.type === 'media-list-greenlight') {
            pageWidth = document.getElementById('page-width').offsetWidth;
            thumbnailWidth = document.getElementById('thumbnail-container').offsetWidth;
            width = pageWidth - thumbnailWidth - 128;
        } else {
            width = this.containerRef.nativeElement.getBoundingClientRect().width;
        }
        this.prevNumberItems = this.numberOfItems;
        this.maxWidth.emit(width);

        if ((this.type === 'thumbs' || this.type === 'media-list' || this.type === 'show-list' || this.type === 'season-banner' || this.type === 'user-recommendations' || this.type === 'hot-list')
            && this.thumbnailType !== 'large' && this.thumbnailType !== 'vertical' && this.thumbnailType !== 'large-vertical') {
            if (width > 2000) {
                this.numberOfItems = 6;
            } else if (width > 1400) {
                this.numberOfItems = 5;
            } else if (width > 900) {
                this.numberOfItems = 4;
            } else if (width > 600) {
                this.numberOfItems = 3;
            } else {
                this.numberOfItems = 3;
            }
        } else if (this.type === 'media-list-channel' || this.thumbnailType === 'large') {
            if (width > 2000) {
                this.numberOfItems = 3;
            } else if (width > 1400) {
                this.numberOfItems = 3;
            } else if (width > 900) {
                this.numberOfItems = 3;
            } else if (width > 600) {
                this.numberOfItems = 2;
            } else {
                this.numberOfItems = 2;
            }
        } else if (this.type === 'media-list-double') {
            if (width > 2000) {
                this.numberOfItems = 6;
            } else if (width > 1400) {
                this.numberOfItems = 6;
            } else if (width > 900) {
                this.numberOfItems = 6;
            } else if (width > 600) {
                this.numberOfItems = 4;
            } else {
                this.numberOfItems = 2;
            }
        } else if (this.type === 'media-list-greenlight') {
            if (width > 1600) {
                this.numberOfItems = 5;
            } else if (width > 1200) {
                this.numberOfItems = 4;
            } else if (width > 900) {
                this.numberOfItems = 3;
            } else if (width > 600) {
                this.numberOfItems = 2;
            } else {
                this.numberOfItems = 1;
            }
        } else if ((this.type === 'thumbs' || this.type === 'media-list' || this.type === 'show-list' || this.type === 'season-banner')
            && this.thumbnailType === 'vertical') {
            if (width > 2000) {
                this.numberOfItems = 6;
            } else if (width > 1400) {
                this.numberOfItems = 5;
            } else if (width > 900) {
                this.numberOfItems = 4;
            } else if (width > 600) {
                this.numberOfItems = 3;
            } else {
                this.numberOfItems = 3;
            }
        } else if ((this.type === 'thumbs' || this.type === 'media-list' || this.type === 'show-list' || this.type === 'season-banner')
            && this.thumbnailType === 'large-vertical') {
            if (width > 2000) {
                this.numberOfItems = 3;
            } else if (width > 1400) {
                this.numberOfItems = 3;
            } else if (width > 900) {
                this.numberOfItems = 3;
            } else if (width > 600) {
                this.numberOfItems = 2;
            } else {
                this.numberOfItems = 2;
            }
        }

        this.width = 100 / (this.numberOfItems / this.rows);
        this.flex = '0 0 ' + this.width + '%';
        this.calcNavButtons();
        this.fillDisplayItems();
    }

    fillDisplayItems() {
        // Else check if transform becomes out of scope vs total displayed items.
        // We want to re-set the transform to match what was previously viewed.
        if (this.taken < this.numberOfItems * 2) {
            this.takeMoreItems(this.numberOfItems * 2);
        } else if ((this.numberOfTransforms * (this.numberOfItems * 2)) >= this.prevTaken) {
            const amount = (this.numberOfTransforms * this.numberOfItems);
            this.takeMoreItems(amount);
            this.setTransforms();
        } else if (this.prevTaken > (this.numberOfTransforms * (this.numberOfItems * 2))) {
            this.setTransforms();
        }
    }

    private setTransforms(): void {
        this.numberOfTransforms = Math.floor((this.prevNumberItems * this.numberOfTransforms) / this.numberOfItems);
        this.transform = 'translateX(' + this.numberOfTransforms * -100 + '%)';
        this.calcNavButtons();
    }

    private takeMoreItems(amount: number) {
        if (this.items) {
            const take = this.taken + amount;
            const nextItems = this.items.slice(this.taken, take);
            this.displayItems = this.displayItems.concat(nextItems);
            this.prevTaken = this.taken;
            this.taken += amount;
        }
    }

    navLeft(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if(!this.left) {
            return;
        }

        this.numberOfTransforms -= 1;
        this.transform = 'translateX(' + this.numberOfTransforms * -100 + '%)';
        this.calcNavButtons();
    }

    navRight(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!this.right) {
            return;
        }

        this.numberOfTransforms += 1;

        this.prevTaken += this.numberOfItems;
        const remaining = this.items.length - (this.numberOfTransforms * this.numberOfItems)
        const rightMovePercentage = remaining > this.numberOfItems ? this.numberOfTransforms : this.numberOfTransforms - 1 + (remaining / this.numberOfItems)
        this.transform = 'translateX(' + rightMovePercentage * -100 + '%)';
        this.calcNavButtons();

        if (this.numberOfTransforms * this.numberOfItems >= this.displayItems.length - this.numberOfItems) {
            this.takeMoreItems(this.numberOfItems);
        }
    }

    private calcNavButtons() {
        if (this.numberOfTransforms <= 0) {
            this.numberOfTransforms = 0;
            this.left = false;
        } else {
            this.left = true;
        }
        this.right = !(this.items && this.numberOfTransforms >= (this.items.length / this.numberOfItems) - 1);

        this.first = (this.numberOfTransforms * this.numberOfItems);
        this.last = (this.numberOfItems - 1) + (this.numberOfTransforms * this.numberOfItems);
    }
}
