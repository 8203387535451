import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ActiveBingoGameResponse, Bingo, BingoCardLayout, BingoCardResponse, BingoGame, BingoGameResponse } from '../models/bingo.models';
import { SocketService } from './socket.service';
import { LivestreamStatus } from '../models/socket-messages/livestream-status';

@Injectable({
    providedIn: 'root'
})
export class BingoService {

    disconnectSocket$ = new Subject<void>();

    private _newGame$ = new BehaviorSubject<number>(undefined);
    get newGame$(): Observable<number> {
        return this._newGame$.asObservable();
    }

    constructor(private http: HttpClient,
                private env: EnvironmentService,
                private socket: SocketService) {

        this.socket.connected$.subscribe(connected => {
            if (connected) {
                this.init();
            } else {
                this.disconnect();
            }
        });
    }

    init() {
        this.socket.onMessage('bingo').pipe(takeUntil(this.disconnectSocket$)).subscribe(data => {
            const parsed = JSON.parse(data);

            switch(parsed.type) {
                case 'new-game':
                    this._newGame$.next(parsed.gameId);
                    break;
            }
        });
    }

    disconnect() {
        this.disconnectSocket$.next();
    }

    getActiveGame() {
        return this.http.get<ActiveBingoGameResponse>(`${this.env.contentHost}/v1/bingo/`);
    }

    getLayout(typeId: number): Observable<BingoCardLayout> {
        return this.http.get<BingoCardLayout>(`${this.env.contentHost}/v1/bingo/${typeId}/layout`);
    }

    getMyGames(typeId: number): Observable<BingoGameResponse[]> {
        return this.http.get<BingoCardResponse[]>(`${this.env.contentHost}/v1/bingo/${typeId}/my-games`);
    }

    getCard(typeId: number, gameId: number): Observable<BingoCardResponse> {
        return this.http.get<BingoCardResponse>(`${this.env.contentHost}/v1/bingo/${typeId}/${gameId}`);
    }

    flipChoice(typeId: number, gameId: number, choiceId: number): Observable<{bingo: boolean}> {
        return this.http.post<{bingo: boolean}>(`${this.env.contentHost}/v1/bingo/${typeId}/${gameId}/flip/${choiceId}`, {});
    }

    startGame(typeId: number): Observable<BingoGame> {
        return this.http.post<any>(`${this.env.contentHost}/v1/bingocreator/${typeId}/start-game`, {});
    }

    getLayouts(): Observable<Bingo[]> {
        return this.http.get<Bingo[]>(`${this.env.contentHost}/v1/bingocreator/layouts`);
    }
}
