import { Component, getPlatform, OnDestroy, OnInit } from '@angular/core';
import { DeviceManagementService } from '../../../services/device-management.service';
import { combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { DeviceModel } from '../../../models/device-management';
import { UiService } from '../../../services/ui.service';

@Component({
    selector: 'app-device-management',
    templateUrl: './device-management.component.html',
    styleUrls: ['./device-management.component.scss']
})
export class DeviceManagementComponent extends AuthenticationOverride implements OnInit, OnDestroy {

    devices$: Observable<DeviceModel[]>;

    activeCount = 0;

    destroy$: Subject<void> = new Subject<void>();

    constructor(public deviceManagementService: DeviceManagementService,
                public config: RemoteConfigService,
                private ui: UiService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router,
                account: AccountService) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshDeviceList();
    }

    refreshDeviceList() {
        this.ui.startLoading();

        this.devices$ = combineLatest([this.deviceManagementService.getDevices(), this.deviceManagementService.deviceIdentifier$]).pipe(
            takeUntil(this.destroy$),
            map(([x, deviceIdentifier]) => {

                console.log('devicemanagement', deviceIdentifier, x);

                if (x.length < 1) {
                    this.ui.stopLoading();
                    return [];
                }

                const thisDevice = x.splice(x.findIndex(y => y.deviceIdentifier === deviceIdentifier), 1)?.[0];
                x.sort((a, b) => {
                    // Sort by isActive first
                    if (a.isActive === b.isActive) {
                        // If isActive is the same, sort by date created
                        return new Date(b.created).getTime() - new Date(a.created).getTime();
                    }
                    // Sort true isActive before false
                    return a.isActive ? -1 : 1;
                });

                x.unshift(thisDevice);
                x?.map(y => {
                    if (!y?.created.toLowerCase().endsWith('z')) {
                        y.created += 'Z';
                    }

                    return y;
                });

                this.activeCount = x.filter(y => y.isActive).length;
                this.ui.stopLoading();
                return x;
            })
        )
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    toggleActivated(device: DeviceModel) {
        if (device.isActive) {
            device.isActive = false;
            this.activeCount--;
        } else {
            if (this.activeCount >= this.config.remoteConfig.device_management_limit) {

                return;
            }
            this.activeCount++;
            device.isActive = true;
        }
        this.deviceManagementService.updateDeviceActive(device.deviceIdentifier, device.isActive).subscribe();

    }

    deleteDevice(device: DeviceModel) {
        this.ui.startLoading();
        this.deviceManagementService.deleteDevice(device.deviceIdentifier).subscribe(() => {
            this.refreshDeviceList();
        });
    }

    getPlatform(device: DeviceModel) {
        const mobile = ['ios', 'android'];
        const tv = ['android-tv', 'fire-tv', 'roku', 'stv', 'tvos'];
        const tablet = ['ipad', 'tablet'];
        const mobileBrowser = ['android', 'ios']

        if (tv.includes(device.platform.toLowerCase())) {
            return 'stv';
        }
        if (tablet.some(x => device.model.toLowerCase().includes(x))) {
            return 'tablet';
        }
        if (mobile.includes(device.platform.toLowerCase()) || mobileBrowser.some(x => device.model.toLowerCase().includes(x))) {
            return 'mobile';
        }
        return 'web';
    }
}
