<div *ngIf="!endOfVideo" class="shaka-container" [style.visibility]="videoLoaded ? 'visible' : 'hidden'"
    [class.player-pip]="pip" [ngClass]="subtitleClass$ | async">
    <div #videoUI id="videoUI" data-shaka-player-container data-shaka-player-cast-receiver-id="{{receiverId}}"
        data-shaka-player-cast-android-receiver-compatible="true">
        <video #videoPlayer data-shaka-player id="video" style="width:100%" autoplay [poster]="poster"></video>

        <app-up-next-switch [class.d-none]="!shown()" class="autoplay-switch" (autoPlayChanged)="updateAutoPlay($event)"></app-up-next-switch>

        <button *ngIf="shown()" (click)="skipBackward()" class="shaka-overlay-button shaka-skip-backward"
            aria-label="Skip Backward"></button>
        <button *ngIf="shown()" (click)="skipForward()" class="shaka-overlay-button shaka-skip-forward"
            aria-label="Skip Forward"></button>

        <button *ngIf="shown() && pip && !isFullScreen" (click)="closePip()"
            class="shaka-overlay-button shaka-skip-close" aria-label="Close"></button>
        <div id="seek-preview"></div>
    </div>
</div>

<div *ngIf="!videoLoaded" class="video-loading-container">
    <div class="poster-background" *ngIf="poster !== undefined" [style.background-image]="'url(\'' + poster + '\')'">
    </div>
    <app-loading-spinner [overrideSize]="true" class="video-spinner"></app-loading-spinner>
</div>

<div *ngIf="endOfVideo" class="end-of-video-container">
    <div *ngIf="endOfVideo">
        <app-video-end-card [endOfVideoTime]="endOfVideoTime"
            [cancelled]="cancelled" (upNextCancelled)="cancelNextVideoTimer()" (watchAgain)="watchAgain()" (playNextVideo)="playNextVideo()"></app-video-end-card>
    </div>
</div>
