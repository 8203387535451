import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RecommendationsService } from '../../services/recommendations.service';
import { Subject, takeUntil } from 'rxjs';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-browse',
    templateUrl: './browse.component.html',
    styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject();
    message: string;
    additionalResults: boolean;

    media = [];
    recommendationId: string;
    count = 30;

    initialised = false;

    constructor(private service: RecommendationsService,
                private ui: UiService,) {
    }

    ngOnInit(): void {
        this.service.getRecommendations('browse-tab', this.count).pipe(takeUntil(this.destroy$))
            .subscribe({
                next: recommendations => {
                    this.recommendationId = recommendations.recommendationId;

                    if (recommendations.media.length > 0) {
                        this.media = this.media.concat(recommendations.media);
                    } else {
                        this.message = 'No more results.';
                    }

                    this.initialised = true;
                },
                error: err => {
                    this.initialised = true;
                    this.ui.toast('error', 'There was an error loading the page!');
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (!this.initialised) {
            return;
        }

        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        if (pos > max - 20) {
            if (this.additionalResults) {
                return;
            }

            this.additionalResults = true;
            this.service.getRecommendations('browse-tab', this.count, this.recommendationId).subscribe(res => {
                this.additionalResults = false;

                if (res.media.length > 0) {
                    this.media = this.media.concat(res.media);
                } else {
                    this.message = 'No more results.';
                }
            });
        }
    }

}
