import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { AccountService } from 'src/app/services/account.service';
import { ChannelResponse, HeroResponse } from '../../models';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { HeroService } from 'src/app/services/hero.service';
import { LiveService } from '../../services/live.service';
import { copyObj } from '../../modules/helper-functions';
import { UiService } from 'src/app/services/ui.service';
import { WatchHistoryService } from '../../services/watch-history.service';
import { UserInfoService } from '../../services/user-info.service';
import { SocketService } from '../../services/socket.service';
import { RecommendationsService } from '../../services/recommendations.service';

@Component({
    selector: 'app-channel-slider-v2',
    templateUrl: './channel-slider-v2.component.html',
    styleUrls: ['./channel-slider-v2.component.scss']
})
export class ChannelSliderV2Component implements OnInit, OnDestroy {

    channels: ChannelResponse[];
    @Input() type: string;
    @Input() header: string;

    hero: HeroResponse;
    isMobileView = false;
    destroy = new Subject<void>();
    @Input() heroResponse: HeroResponse;

    constructor(
        private ui: UiService,
        private authenticationService: AccountService,
        private channelsService: ChannelsService,
        private heroService: HeroService,
        private liveService: LiveService,
        private watchHistoryService: WatchHistoryService,
        private userInfo: UserInfoService,
        private recommendationService: RecommendationsService) {
        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    async ngOnInit() {
        this.ui.startLoading();
        if (this.type === 'standard') {

            let hero$ = this.heroService.getHeroV5Anon();
            if (this.isAuthenticated()) {
                hero$ = this.heroService.getHeroV5Auth();
            }

            hero$.subscribe(res => {
                this.heroResponse = res;
            });

            if (this.authenticationService.isAuthenticated()) {
                await lastValueFrom(this.userInfo.getUserInfoQuery('subscription'));
            }

            const [channels] = await Promise.all([
                lastValueFrom(this.channelsService.getChannels(false, !this.userInfo.hasSubscription)),
                this.watchHistoryService.refreshWatchHistory()
            ]).finally(() => {
                this.ui.stopLoading();
            });

            this.channelsService.refreshUserChannels();

            this.channels = channels;

            if (this.isAuthenticated()) {
                const hotChannel = this.channels.find(x => x.type === 'hot-list');
                if (hotChannel) {
                    this.recommendationService.getRecommendations('popular').subscribe(x => {
                        hotChannel.media = x.media;
                    })
                }

                const recommendedChannel = this.channels.find(x => x.type === 'user-recommendations');
                if (recommendedChannel) {
                    this.recommendationService.getRecommendations('recommended-for-you').subscribe(x => {
                        recommendedChannel.media = x.media;
                    })
                }
            }

            this.liveService.status$.pipe(takeUntil(this.destroy)).subscribe(async status => {
                if (!status) {
                    return;
                }

                const index = this.channels.findIndex(x => x.slug === 'latest-uploads' || x.slug === 'latest-videos' || x.id === 23);
                const latestChannel = this.channels[index];
                const media = await lastValueFrom(this.liveService.getLiveMediaV2());

                for (let i = 0; i < latestChannel.media.length; i++) {
                    if (latestChannel.media[i].livestreamId && !media.some(x => x.livestreamId === latestChannel.media[i].livestreamId)) {
                        this.channels[index].media.splice(i, 1);
                        this.channels[index] = copyObj(this.channels[index]);
                    }
                }

                for (let m of media) {
                    if (latestChannel.media.some(x => x.livestreamId === m.livestreamId)) {
                        continue;
                    }
                    latestChannel.media.unshift(m);
                    this.channels[index] = copyObj(latestChannel);
                }
            });

        } else if (this.type === 'shows') {
            this.channelsService.getChannelsShows()
                .subscribe({
                    next: res => {
                        this.channels = res;
                        if (this.channels[0].type === 'season-banner') {
                            this.header = undefined;
                        }
                    },
                    error: error => {
                        this.authenticationService.checkUnauthorized(error);
                    }
                }).add(() => {
                this.ui.stopLoading();
            });
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    isAuthenticated() {
        return this.authenticationService.isAuthenticated();
    }
}
