<!-- Header -->
<ng-container *ngIf="localisationService.translationsLoaded">
	<app-menu *ngIf="!hideMenus"></app-menu>

	<router-outlet>

	</router-outlet>

	<proto-confirm *ngIf="confirmObj !== undefined" [confirmObj]="confirmObj"></proto-confirm>

	<app-push-notification></app-push-notification>

	<app-mobile-apps *ngIf="showMobileApps && !hideMenus" (close)="showMobileApps = false"></app-mobile-apps>

	<app-simple-alert *ngIf="simpleAlert" [simpleAlert]="simpleAlert"></app-simple-alert>
	<app-footer *ngIf="!blockAccess && !hideMenus"></app-footer>

	<app-modal-legal-documents *ngIf="showLegalDocumentsModal" (onHide)="toggleLegalDocumentModal()">
	</app-modal-legal-documents>
</ng-container>


<app-modal-loading *ngIf="loading || !localisationService.translationsLoaded"></app-modal-loading>
<app-toast></app-toast>
