export interface BingoCardResponse {
    id: number;
    gameId: number;
    started: string;
    bingo: string;
    choices?: BingoCardChoiceResponse[];
}

export interface Bingo {
    id: number;
    name: string;
}

export interface ActiveBingoGameResponse {
    bingoId: number;
    gameId: number;
}

export interface BingoCardChoiceResponse {
    id: number;
    word: string;
    flipped: boolean;
    disabled?: boolean;
}

export interface BingoCardLayout {
    id: number;
    name: string;
    bannerImage: string;
    backgroundImage: string;
    checkmarkImage: string;
}

export interface BingoGame {
    id: number;
    bingoId: number;
    startDate: string;
    gameState: number;
}

export interface BingoGameResponse {
    id: number;
    gameId: number;
    started: string;
}
