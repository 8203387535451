<div class="main main-full main-responsive">
    <app-title [title]="'account-smart-tv-login-code' | translate"></app-title>

<!--    <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">-->
<!--        <span class="sr-only">{{ "loading" | translate }}</span>-->
<!--    </div>-->
    <div class="row py-3 my-3">
        <div class="col-12">
            <ng-container *ngIf="isV1">
                <ng-container *ngIf="loading">
                    <p>{{ 'account-code-checking' | translate }}</p>
                </ng-container>

                <ng-container *ngIf="!loading && smartCode && smartCode.code && !tvResponse">
                    <p>{{ "account-smart-code-success" | translate }}</p>
                    <h3>{{smartCode.code}}</h3>

                    <ngb-progressbar type="success" [value]="timer" [max]="timerLength" height="24px">
                        <div class="justify-content-center d-flex position-absolute w-100">{{timer}} {{ "seconds" |
                            translate}}</div>
                    </ngb-progressbar>
                </ng-container>

                <ng-container *ngIf="tvResponse">
                    <p>Is this the correct device?</p>
                    <h3>{{tvResponse.device}}</h3>
                    <button class="btn btn-outline-light btn-slim" (click)="correctDevice(tvResponse.hash)">Correct</button>
                    <button class="btn btn-outline-light btn-slim ml-2"
                        (click)="wrongDevice(tvResponse.hash)">Cancel</button>
                </ng-container>

                <ng-container *ngIf="errorMessage">
                    <div class="text-danger font-weight-bold mt-2">
                        {{errorMessage}}
                    </div>
                </ng-container>

                <or-block></or-block>
            </ng-container>
            <app-smart-tv-code class="d-block mt-3"></app-smart-tv-code>

            <div class="d-block"></div>
            <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
        </div>
    </div>
</div>
