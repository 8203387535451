<div class="main main-full main-responsive wide">
    <app-title [title]="'Device Management'"></app-title>

    <div>
        Active devices: {{activeCount}} of {{config.remoteConfig.device_management_limit}}
    </div>

    <div *ngIf="devices$ | async as devices" class="mt-4 device-container">
        <div *ngFor="let device of devices" class="device" [class.active]="device.isActive">
            <div class="top-bar">
                <div class="model">
                    <ng-container *ngIf="getPlatform(device) as platform">
                        <svg *ngIf="platform === 'mobile'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm0-80h400v-480H280v480Zm0-560h400v-40H280v40Zm0 0v-40 40Zm0 640v40-40Z"/></svg>
                        <svg *ngIf="!platform || platform === 'web'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M40-120v-80h880v80H40Zm120-120q-33 0-56.5-23.5T80-320v-440q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v440q0 33-23.5 56.5T800-240H160Zm0-80h640v-440H160v440Zm0 0v-440 440Z"/></svg>
                        <svg *ngIf="platform === 'tablet'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M120-160q-33 0-56.5-23.5T40-240v-480q0-33 23.5-56.5T120-800h720q33 0 56.5 23.5T920-720v480q0 33-23.5 56.5T840-160H120Zm40-560h-40v480h40v-480Zm80 480h480v-480H240v480Zm560-480v480h40v-480h-40Zm0 0h40-40Zm-640 0h-40 40Z"/></svg>
                        <svg *ngIf="platform === 'stv'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M320-120v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z"/></svg>
                    </ng-container>
                    {{device.model}}
                </div>
                <div class="current-device-pill" *ngIf="device.deviceIdentifier === (deviceManagementService.deviceIdentifier$ | async)">
                    CURRENT DEVICE
                </div>

                <div class="buttons d-lg-flex d-none">
                    <button class="text-btn" (click)="toggleActivated(device)">{{device.isActive ? 'Deactivate' : 'Activate'}}</button>
                    <button class="icon-btn" (click)="deleteDevice(device)" *ngIf="device.deviceIdentifier !== (deviceManagementService.deviceIdentifier$ | async)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                    </button>
                </div>
            </div>
            <div>
                {{device.created | date : 'medium'}}
            </div>
            <div class="buttons d-lg-none d-flex mt-2">
                <button class="text-btn" (click)="toggleActivated(device)">{{device.isActive ? 'Deactivate' : 'Activate'}}</button>
                <button class="icon-btn" (click)="deleteDevice(device)" *ngIf="device.deviceIdentifier !== (deviceManagementService.deviceIdentifier$ | async)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                </button>
            </div>
        </div>
    </div>
    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>

